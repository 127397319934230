import $api from '../http';
import { AxiosResponse } from 'axios';

export default class ReferencesService {
	static async getDiveMethods(): Promise<AxiosResponse<any>> {
		return $api.get<any>('/references/dive-method/all');
	}
	static async getOneDiveMethod(id: string): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/references/dive-method/${id}`);
	}
	static async updateOneDiveMethod(
		id: string,
		name: string
	): Promise<AxiosResponse<any>> {
		return $api.post<any>(`/references/dive-method/update/${id}`, { name });
	}
	static async deleteOneMethod(id: string): Promise<AxiosResponse<any>> {
		return $api.delete<any>(`/references/dive-method/${id}`);
	}
	static async addDiveMethod(name: string): Promise<AxiosResponse<any>> {
		return $api.post<any>('/references/dive-method/add', { name });
	}
	static async getEquipments(): Promise<AxiosResponse<any>> {
		return $api.get<any>('/references/equipment-type/all');
	}
	static async getOneEquipment(id: string): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/references/equipment-type/${id}`);
	}
	static async udateOneEquip(
		id: string,
		name: string
	): Promise<AxiosResponse<any>> {
		return $api.post<any>(`/references/equipment-type/update/${id}`, { name });
	}
	static async deleteOneEquip(id: string): Promise<AxiosResponse<any>> {
		return $api.delete<any>(`/references/equipment-type/${id}`);
	}
	static async addEquipment(name: string): Promise<AxiosResponse<any>> {
		return $api.post<any>('/references/equipment-type/add', { name });
	}
	static async getBreathingMixes(): Promise<AxiosResponse<any>> {
		return $api.get<any>('/references/breathig-mix/all');
	}
	static async getOneBreathingMix(id: string): Promise<AxiosResponse<any>> {
		return $api.get<any>(`/references/breathig-mix/${id}`);
	}
	static async udateOneBreathingMix(
		id: string,
		name: string
	): Promise<AxiosResponse<any>> {
		return $api.post<any>(`/references/breathig-mix/update/${id}`, { name });
	}
	static async deleteOneBreathingMix(id: string): Promise<AxiosResponse<any>> {
		return $api.delete<any>(`/references/breathig-mix/${id}`);
	}
	static async addBreathingMix(name: string): Promise<AxiosResponse<any>> {
		return $api.post<any>('/references/breathig-mix/add', { name });
	}
	static async upload(file: any): Promise<AxiosResponse<any>> {
		const formData = new FormData();
		formData.append('file', file);
		return $api.post('/sja-risk-matrix', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	}
}
