import { FC, useContext, useState, useEffect } from 'react';
import { Context } from '../../index';
import { observer } from 'mobx-react-lite';
import Input from '../../component/Input/Input';
import Button from '../../component/Button/Button';
import styles from './Journal.module.css';
import Select from '../../component/Input/Select';
import { IUser } from '../../models/IUser';
import UserService from '../../services/UserSevice';
import ReferencesService from '../../services/ReferencesService';
import Divers from './Divers';
import JournalService from '../../services/JournalService';
import { JournalDTO } from '../../dto/Journal-dto';
import PDFService from '../../services/PDFService';
import MailService from '../../services/MailService';

const Journal: FC = () => {
	const { store } = useContext(Context);
	const [valid, setValid] = useState<boolean>(false);
	const [liders, setLiders] = useState<IUser[]>([]);
	const [divers, setDivers] = useState<IUser[]>([]);
	const [assistents, setAssistents] = useState<IUser[]>([]);
	const [diveMethods, setDiveMethods] = useState<string[]>([]);
	const [date, setDate] = useState<any>({
		value: '',
		error: false,
	});
	const [client, setClient] = useState<any>({
		value: '',
		error: false,
	});
	const [client_location, setClientLocation] = useState<any>({
		value: '',
		error: false,
	});
	const [location, setLocation] = useState<any>({
		value: '',
		error: false,
	});
	const [diveMethod, setDiveMethod] = useState<any>({
		value: '',
		error: false,
	});
	const [description, setDescription] = useState<any>({
		value: '',
		error: false,
	});
	const [lider, setLider] = useState<any>({
		id: '',
		email: '',
		error: false,
	});
	const [diver1, setDiver1] = useState<any>({
		id: '',
		email: '',
		error: false,
	});
	const [diver2, setDiver2] = useState<any>({
		id: '',
		email: '',
		error: false,
	});
	const [assistent, setAssistent] = useState<any>({
		id: '',
		email: '',
		error: false,
	});
	const [assistent2, setAssistent2] = useState<any>({
		id: '',
		email: '',
		error: false,
	});
	const [clientRepresentative, setClientRepresentative] =
		useState<any>({
			value: '',
			error: false,
		});
	const [supervisorVerified, setSupervisorVerified] = useState<any>({
		value: false,
		error: false,
	});
	const [havePlan, setHavePlan] = useState<any>({
		value: false,
		error: false,
	});
	const [diver1_info, setDiver1_info] = useState<any>(null);
	const [diver2_info, setDiver2_info] = useState<any>(null);
	const [checkDiver, setCheckDiver] = useState<any>(false);
	const [pdfFile, setPdfFile] = useState<any>(null);
	const [buttonSave, setButtonSave] = useState<string>('Save');

	const araayOfFieldsNormal = [
		{ var: date, setVar: setDate },
		{ var: client, setVar: setClient },
		{
			var: client_location,
			setVar: setClientLocation,
		},
		{ var: location, setVar: setLocation },
		{ var: diveMethod, setVar: setDiveMethod },
		{ var: description, setVar: setDescription },
		{
			var: clientRepresentative,
			setVar: setClientRepresentative,
		},
		{
			var: supervisorVerified,
			setVar: setSupervisorVerified,
		},
		{ var: havePlan, setVar: setHavePlan },
	];
	const araayOfFieldsSimpleObject = [
		{ var: lider, setVar: setLider },
		{ var: diver1, setVar: setDiver1 },
		{ var: diver2, setVar: setDiver2 },
		{ var: assistent, setVar: setAssistent },
	];

	const [reload, setReload] = useState<boolean>(false);

	const [status, setStatus] = useState(navigator.onLine);
	const setOnline = () => {
		setStatus(true);
	};
	const setOffline = () => {
		setStatus(false);
	};
	useEffect(() => {
		window.addEventListener('online', setOnline);
		window.addEventListener('offline', setOffline);
		return () => {
			window.removeEventListener('online', setOnline);
			window.removeEventListener('offline', setOffline);
		};
	}, []);
	function validation() {
		setCheckDiver(true);
		let valid = true;
		araayOfFieldsNormal.forEach((field) => {
			if (!field.var.value) {
				field.setVar({
					...field.var,
					error: true,
				});
				valid = false;
			}
		});
		araayOfFieldsSimpleObject.forEach((field) => {
			if (!field.var.id || !field.var.email) {
				field.setVar({
					...field.var,
					error: true,
				});
				valid = false;
			}
		});
		setTimeout(() => {
			setCheckDiver(false);
		}, 1000);
		if (!diver1_info.valid || !diver2_info.valid) {
			valid = false;
		}
		setValid(valid);
		console.log('validation');
		console.log(diver1_info);
		console.log(diver2_info);
		console.log("valid");
		const diver1DTO = new JournalDTO(diver1_info);
		const diver2DTO = new JournalDTO(diver2_info);
		console.log(diver1DTO);
		console.log(diver2DTO);

		return valid;
	}
	async function getLiders() {
		const response = await UserService.getLiders();
		setLiders(response.data);
	}
	async function getDivers() {
		const response = await UserService.getDivers();
		setDivers(response.data);
	}
	async function getAssistents() {
		const response = await UserService.fetchUsers();
		setAssistents(response.data);
	}
	async function getDiveMethods() {
		const response = await ReferencesService.getDiveMethods();
		setDiveMethods(response.data);
		console.log(response.data);
	}
	async function createJournal() {
		const valid = await validation();
		if (valid) {
			const diver1DTO = new JournalDTO(diver1_info);
			const diver2DTO = new JournalDTO(diver2_info);
			const response = await JournalService.add(
				date.value,
				client.value,
				client_location.value,
				location.value,
				diveMethod.value,
				description.value,
				lider,
				diver1,
				diver2,
				assistent,
				assistent2,
				clientRepresentative.value,
				supervisorVerified.value,
				havePlan.value,
				diver1DTO,
				diver2DTO
			);
			if (response.status === 200) {
				setButtonSave('Lagret');
				setTimeout(() => {
					setButtonSave('Vente! En PDF blir generert.');
				}, 500);
				const pdf = await PDFService.createPDF(response.data);
				if (pdf.status === 200) {
					setPdfFile(pdf.data);
					window.open(`./pdf/journal_${pdf.data}.pdf`);
					let emailList = `dykkejournal@gmail.com, ${diver1.email}, ${diver2.email}, ${lider.email}, ${assistent.email}, ${assistent2.email}`;
					const mail = await MailService.sendMail(
						emailList,
						pdf.data
					);
					clearForm();
					setReload(!reload);
				}
			} else {
				setButtonSave('Feil');
				setTimeout(() => {
					setButtonSave('Lagre');
				}, 1000);
			}
		}
	}
	function clearForm() {
		araayOfFieldsNormal.forEach((field) => {
			field.setVar({
				...field.var,
				value: '',
				error: false,
			});
		});
		araayOfFieldsSimpleObject.forEach((field) => {
			field.setVar({
				...field.var,
				id: '',
				email: '',
				error: false,
			});
		});
		setDiver1_info(null);
		setDiver2_info(null);
		setValid(false);
		setCheckDiver(false);
		setButtonSave('Save');
	}
	function clearDiver1() {
		setDiver1({
			...diver1,
			id: '',
			email: '',
			error: false,
		});
		setDiver1_info(null);
		setValid(false);
		setCheckDiver(false);
		console.log('clearDiver');
		console.log(diver1_info);
		console.log(diver2_info);
		console.log(valid);
		console.log(checkDiver);
	}
	function clearDiver2() {
		setDiver2({
			...diver2,
			id: '',
			email: '',
			error: false,
		});
		setDiver2_info(null);
		setValid(false);
		setCheckDiver(false);
		console.log('clearDiver');
		console.log(diver1_info);
		console.log(diver2_info);
		console.log(valid);
		console.log(checkDiver);
	}
	useEffect(() => {
		if (localStorage.getItem('token')) {
			store.checkAuth();
		}
	}, []);

	useEffect(() => {
		if (diver1_info && diver2_info) {
			if (diver1_info.valid && diver2_info.valid) {
				validation();
			}
		}
	}, [diver1_info, diver2_info]);

	useEffect(() => {
		getLiders();
		getDivers();
		getAssistents();
		getDiveMethods();
	}, [reload]);
	useEffect(() => {
		setReload(!reload);
	}, [diveMethod, lider, diver1, diver2, assistent]);

	return (
		<>
			{store.isAuth ? (
				<div className={styles.journal}>
					<div className={styles.journal_part}>
						<h1>Dykkejournal</h1>
						<div className={styles.journal_part__form}>
							<div className={styles.journal_part__item}>
								<div
									{...(date.error
										? { className: styles.error }
										: {})}>
									<div className={styles.journal_part__item_element}>
										<p> Dato </p>
										<Input
											type="date"
											value={date.value}
											onchange={(e) =>
												setDate({
													value: e.target.value,
													error: false,
												})
											}
										/>
									</div>
								</div>
								<div
									{...(client.error
										? { className: styles.error }
										: {})}>
									<div className={styles.journal_part__item_element}>
										<p> Oppdragsgiver </p>
										<Input
											type="text"
											value={client.value}
											onchange={(e) =>
												setClient({
													value: e.target.value,
													error: false,
												})
											}
										/>
									</div>
								</div>
								<div
									{...(client_location.error
										? { className: styles.error }
										: {})}>
									<div className={styles.journal_part__item_element}>
										<p> Oppdragsgiver adresse </p>
										<Input
											type="text"
											value={client_location.value}
											onchange={(e) =>
												setClientLocation({
													value: e.target.value,
													error: false,
												})
											}
										/>
									</div>
								</div>
								<div
									{...(location.error
										? { className: styles.error }
										: {})}>
									<div className={styles.journal_part__item_element}>
										<p> Sted </p>
										<Input
											type="text"
											value={location.value}
											onchange={(e) =>
												setLocation({
													value: e.target.value,
													error: false,
												})
											}
										/>
									</div>
								</div>
								<div
									{...(diveMethod.error
										? { className: styles.error }
										: {})}>
									<div className={styles.journal_part__item_element}>
										<p> Dykkemetode </p>
										<Select
											options={diveMethods}
											context="Dykkemetode"
											callback={(e) =>
												setDiveMethod({
													value: e,
													error: false,
												})
											}
											addnew={true}
											service={ReferencesService.addDiveMethod}
											{...(!diveMethod.value
												? { clear: true }
												: { clear: false })}
										/>
									</div>
								</div>
								<div
									{...(description.error
										? { className: styles.error }
										: {})}>
									<div className={styles.journal_part__item_element}>
										<p> Arbeidsbeskrivelse </p>
										<Input
											type="textarea"
											value={description.value}
											onchange={(e) =>
												setDescription({
													value: e.target.value,
													error: false,
												})
											}
										/>
									</div>
								</div>
							</div>
							<div className={styles.journal_part__item}>
								<div
									{...(lider.error
										? { className: styles.error }
										: {})}>
									<div className={styles.journal_part__item_element}>
										<p> Dykkeleder </p>
										<Select
											options={liders}
											callback={(e) =>
												setLider({
													id: e.id,
													email: e.email,
													error: false,
												})
											}
											addnew={true}
											component={'user'}
											{...(!lider.id
												? { clear: true }
												: { clear: false })}
										/>
									</div>
									<div className={styles.journal_part__item_element}>
										<p> Dykkeleder Epost </p>
										<Input
											type="text"
											value={lider.email}
											onchange={(e) =>
												setLider({
													id: lider.id,
													email: e.target.value,
													error: false,
												})
											}
										/>
									</div>
								</div>
								<div
									{...(diver1.error
										? { className: styles.error }
										: {})}>
									<div className={styles.journal_part__item_element}>
										<p> Dykker 1 </p>
										<Select
											options={divers}
											callback={(e) =>
												setDiver1({
													id: e.id,
													email: e.email,
													name: `${e.name} ${e.surname}`,
													error: false,
												})
											}
											addnew={true}
											component={'user'}
											{...(!diver1.id
												? { clear: true }
												: { clear: false })}
										/>
									</div>
									<div className={styles.journal_part__item_element}>
										<p> Dykker 1 Epost </p>
										<Input
											type="text"
											value={diver1.email}
											onchange={(e) =>
												setDiver1({
													id: diver1.id,
													email: e.target.value,
													error: false,
												})
											}
										/>
									</div>
								</div>
								<div
									{...(diver2.error
										? { className: styles.error }
										: {})}>
									<div className={styles.journal_part__item_element}>
										<p> Dykker 2 </p>
										<Select
											options={divers}
											callback={(e) =>
												setDiver2({
													id: e.id,
													email: e.email,
													name: `${e.name} ${e.surname}`,
													error: false,
												})
											}
											addnew={true}
											component={'user'}
											{...(!diver2.id
												? { clear: true }
												: { clear: false })}
										/>
									</div>
									<div className={styles.journal_part__item_element}>
										<p> Dykker 2 Epost </p>
										<Input
											type="text"
											value={diver2.email}
											onchange={(e) =>
												setDiver2({
													id: diver2.id,
													email: e.target.value,
													error: false,
												})
											}
										/>
									</div>
								</div>
								<div
									{...(assistent.error
										? { className: styles.error }
										: {})}>
									<div className={styles.journal_part__item_element}>
										<p> Assistent </p>
										<Select
											options={assistents}
											callback={(e) =>
												setAssistent({
													id: e.id,
													email: e.email,
													error: false,
												})
											}
											addnew={true}
											component={'user'}
											{...(!assistent.id
												? { clear: true }
												: { clear: false })}
										/>
									</div>
									<div className={styles.journal_part__item_element}>
										<p> Assistent Epost </p>
										<Input
											type="text"
											value={assistent.email}
											onchange={(e) =>
												setAssistent({
													id: assistent.id,
													email: e.target.value,
													error: false,
												})
											}
										/>
									</div>
								</div>
								<div
									{...(assistent2.error
										? { className: styles.error }
										: {})}>
									<div className={styles.journal_part__item_element}>
										<p> Assistent 2 </p>
										<Select
											options={assistents}
											callback={(e) =>
												setAssistent2({
													id: e.id,
													email: e.email,
													error: false,
												})
											}
											addnew={true}
											component={'user'}
											{...(!assistent2.id
												? { clear: true }
												: { clear: false })}
										/>
									</div>
									<div className={styles.journal_part__item_element}>
										<p> Assistent 2 Epost </p>
										<Input
											type="text"
											value={assistent2.email}
											onchange={(e) =>
												setAssistent({
													id: assistent2.id,
													email: e.target.value,
													error: false,
												})
											}
										/>
									</div>
								</div>
								<div
									{...(clientRepresentative.error
										? { className: styles.error }
										: {})}>
									<div className={styles.journal_part__item_element}>
										<p> Kunderepresentant </p>
										<Input
											type="text"
											value={clientRepresentative.value}
											onchange={(e) =>
												setClientRepresentative({
													value: e.target.value,
													error: false,
												})
											}
										/>
									</div>
								</div>
							</div>
						</div>

						<div
							className={`${styles.journal_part__item} ${styles.boolean_button}`}>
							<div
								{...(supervisorVerified.error
									? { className: styles.error }
									: {})}>
								<div
									className={`${styles.journal_part__item_element} ${styles.bool}`}>
									<Input
										type="checkbox"
										checked={supervisorVerified.value}
										onchange={(e) =>
											setSupervisorVerified({
												value: e.target.checked,
												error: false,
											})
										}
									/>
									<p>
										{' '}
										Sikker Jobb Analyse utført og gjennomgått av
										dykkeleder{' '}
									</p>
								</div>
							</div>
							<div
								{...(havePlan.error
									? { className: styles.error }
									: {})}>
								<div
									className={`${styles.journal_part__item_element} ${styles.bool}`}>
									<Input
										type="checkbox"
										checked={havePlan.value}
										onchange={(e) =>
											setHavePlan({
												value: e.target.checked,
												error: false,
											})
										}
									/>
									<p>
										{' '}
										Beredskapsplan foreligger og personellet kjenner
										sine oppgaver{' '}
									</p>
								</div>
							</div>
						</div>
					</div>
					{supervisorVerified.value && havePlan.value && diver1.id ? (
						<div className={styles.journal_part}>
							<h1>Dykker {diver1.name}</h1>
							<Button
								text="Clear Diver 1 info"
								callback={clearDiver1}
								classname="red"
							/>
							<Divers
								type={1}
								callback={setDiver1_info}
								{...(checkDiver ? { validation: checkDiver } : {})}
								divermethod={diveMethod.value.name}
							/>
						</div>
					) : null}

					{supervisorVerified.value && havePlan.value && diver2.id ? (
						<div className={styles.journal_part}>
							<h1>Dykker {diver2.name}</h1>
							<Button
								text="Clear Diver 2 info"
								callback={clearDiver2}
								classname="red"
							/>
							<Divers
								type={2}
								callback={setDiver2_info}
								{...(checkDiver ? { validation: checkDiver } : {})}
								divermethod={diveMethod.value.name}
							/>
						</div>
					) : null}
					<div className={styles.journal_part_button}>
						<Button
							{...(status ? { disabled: false } : { disabled: true })}
							{...(valid
								? { text: `${buttonSave}` }
								: { text: 'Validere' })}
							{...(valid
								? {
									callback: () => {
										createJournal();
									},
								}
								: {
									callback: () => {
										setCheckDiver(true);
										validation();
									},
								})}
							{...(valid ? { classname: 'green' } : {})}
						/>
						{!valid ? (
							<Button
								text="Nullstill"
								onclick={() => {
									clearForm();
								}}
							/>
						) : null}
					</div>
				</div>
			) : null}
		</>
	);
};

export default observer(Journal);
