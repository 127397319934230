import * as yup from 'yup';
const phoneRegExp = /^(\+?1\s(\(\d{3}\)\s?)?\d{3}[-\s]?\d{4}$|\+\d{1,3}[-\s]?\d{1,4}[-\s]?\d{1,4}[-\s]?\d{1,9}$)/;

export const workInstructionValidationSchema = yup.object().shape({
	name: yup.string().required().max(100),
	location: yup.string().required(),
	date: yup.date().required(),
	duration: yup.number().required(),
	client: yup.string().required(),
	client_rep: yup.string().required(),
	client_phone: yup.string().required().matches(phoneRegExp, 'Phone number is not valid'),
	depth_estimated: yup.number().required().max(99),
	diving_certificate: yup.string().required().oneOf(['A', 'B']),
	diving_station: yup.string().required().max(30),
	distribution_list: yup.array().required(),
	surface_provided: yup.boolean().required(),
	scuba: yup.boolean().required(),
	description: yup.string().required().max(500),
	procedure: yup.string().required().max(500),
	special_relationships: yup.string().required().max(500),
	security_measures: yup.string().required().max(500),
	uv_welding: yup.boolean().required(),
	uv_burning: yup.boolean().required(),
	use_of_borhammer: yup.boolean().required(),
	use_of_air_sucker: yup.boolean().required(),
	use_of_angle_cutter: yup.boolean().required(),
	use_of_lift_balloon: yup.boolean().required(),
	anhuker: yup.boolean().required(),
	use_of_polishing_machine: yup.boolean().required(),
	files: yup.array().notRequired(),
});
