import {FC, useState} from "react";
import { observer } from "mobx-react-lite";
import styles from "./Input.module.css";
interface IProps {
    type: string;
    placeholder?: string;
    value?: any;
    onchange?: (e: any) => void;
    classname?: string;
    checked?: boolean;
    step?: string;
}

const Input: FC<IProps> = ({type, placeholder, value, onchange, classname, checked, step}) => {
    return (
        <>
        {type === "textarea" ? 
            <textarea
                className={`${styles.input} ${styles.textarea}  ${styles[classname || ""]}`}
                placeholder={placeholder}
                value={value}
                onChange={onchange}
            />
        : 
        <input
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onchange}
            checked={checked}
            className={`${styles.input} ${styles[classname || ""]}`}
            {...step && {step: step}}
        />
        }
        </>
    )
}

export default observer(Input);
