import React from 'react';
import styles from './Modal.module.css';

interface IModalProps {
	isVisible: boolean;
	title: string;
	widthVh?: string;
	heightVh?: string;
	content: React.ReactNode;
	footer?: React.ReactNode;
	onClose: () => void;
}
interface IkeyDownHandler {
	key: string;
}
const Modal = ({
	isVisible = false,
	title,
	widthVh,
	heightVh,
	content,
	footer,
	onClose,
}: IModalProps) => {
	const keydownHandler = ({ key }: IkeyDownHandler) => {
		switch (key) {
			case 'Escape':
				onClose();
				break;
			default:
		}
	};
	const [modalView, setModalView] = React.useState<any>('');
	if (widthVh && !heightVh) {
		heightVh = widthVh;
	}
	function closeModal() {
		onClose();
	}

	React.useEffect(() => {
		document.addEventListener('keydown', keydownHandler);
		return () =>
			document.removeEventListener('keydown', keydownHandler);
	});

	return !isVisible ? null : (
		<div className={styles.modal} onClick={onClose}>
			{widthVh ? (
				<div
					className={styles.modal_dialog}
					style={{ width: `${widthVh}`, height: `${heightVh}` }}
					onClick={(e) => e.stopPropagation()}>
					<div className={styles.modal_header}>
						<h3 className={styles.modal_title}>{title}</h3>
						<span className={styles.modal_close} onClick={onClose}>
							&times;
						</span>
					</div>
					<div className={styles.modal_body}>
						<div className={styles.modal_content}>{content}</div>
					</div>
					{footer && (
						<div className={styles.modal_footer}>{footer}</div>
					)}
				</div>
			) : (
				<div
					className={styles.modal_dialog}
					onClick={(e) => e.stopPropagation()}>
					<div className={styles.modal_header}>
						<h3 className={styles.modal_title}>{title}</h3>
						<span className={styles.modal_close} onClick={onClose}>
							&times;
							{modalView}
						</span>
					</div>
					<div className={styles.modal_body}>
						<div className={styles.modal_content}>{content}</div>
					</div>
					{footer && (
						<div className={styles.modal_footer}>{footer}</div>
					)}
				</div>
			)}
		</div>
	);
};

export default Modal;
