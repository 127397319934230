import { IUser } from './../models/IUser';
import { useNavigate } from 'react-router-dom';
import { makeAutoObservable } from 'mobx';
import AuthService from '../services/AuthService';
import axios from 'axios';
import { AuthResponse } from './../models/response/AuthResponse';
import { API_URL } from './../http/';

export default class Store {
	user = {} as IUser;
	isAuth = false;
	isLoading = false;

	constructor() {
		makeAutoObservable(this);
	}

	setAuth(bool: boolean) {
		this.isAuth = bool;
	}

	setUser(user: IUser) {
		this.user = user;
	}

	setLoading(bool: boolean) {
		this.isLoading = bool;
	}
	async login(email: string, password: string) {
		try {
			const response = await AuthService.login(email, password);
			localStorage.setItem('token', response.data.accessToken);
			this.setAuth(true);
			this.setUser(response.data.user);
		} catch (e: any) {
			console.log(e.response?.data?.message);
		}
	}
	async registration(
		name: string,
		surname: string,
		email: string,
		password: string,
		userType: number
	) {
		try {
			const response = await AuthService.registration(
				name,
				surname,
				email,
				password,
				userType
			);
		} catch (e: any) {
			console.log(e.response?.data?.message);
		}
	}

	async logout() {
		try {
			const response = await AuthService.logout();
			localStorage.removeItem('token');
			this.setAuth(false);
			this.setUser({} as IUser);
			// переход на сайт https://sdi-subsea.com/
			window.location.href = 'https://sdi-subsea.com/';
		} catch (e: any) {
			console.log(e.response?.data?.message);
		}
	}

	async checkAuth() {
		this.setLoading(true);
		try {
			const response = await axios.get<AuthResponse>(
				`${API_URL}/refresh`,
				{
					withCredentials: true,
				}
			);
			localStorage.setItem('token', response.data.accessToken);
			this.setAuth(true);
			this.setUser(response.data.user);
		} catch (e: any) {
			console.log(e.response?.data?.message);
		} finally {
			this.setLoading(false);
		}
	}
}
