import React, {FC, useContext, useState} from "react";
import { Context } from "../../index";
import Input from "../Input/Input";
import Button from "../Button/Button";
import styles from "./LoginForm.module.css";

interface IProps {
    callback?: () => void;
}
const LoginForm: FC<IProps> = ({callback}) => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const { store } = useContext(Context)
    return (
        <div className={styles.login}>
            <div>
            <Input
                type="text"
                placeholder="Epost"
                value={email}
                onchange={e => setEmail(e.target.value)}
            />
            </div>
            <div>
            <Input
                type="password"
                placeholder="Passord"
                value={password}
                onchange={e => setPassword(e.target.value)}
            />
            </div>
            <div>
            <Button
                text="Logg Inn"
                onclick={() => store.login(email, password)}
                callback={callback}
            />
            </div>
        </div>
    
    )
}

export default LoginForm;