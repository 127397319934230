import React, { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './Input.module.css';
import Modal from '../Modal/Modal';
import AddReference from '../Forms/AddReverence/AddReference';
import User from '../Forms/User/User';

interface IProps {
	options: any[];
	callback?: (value: any) => void;
	context?: string;
	values?: any;
	addnew?: boolean;
	service?: (value: any) => Promise<any>;
	component?: string;
	clear?: boolean;
	addInput?: boolean;
}

const Select: FC<IProps> = ({
	options,
	callback,
	context,
	values,
	addnew,
	service,
	component,
	clear,
	addInput,
}) => {
	const [value, setValue] = useState<any>(null);
	const [open, setOpen] = useState<boolean>(false);
	const [modal, setModal] = useState<boolean>(false);
	useEffect(() => {
		if (values) {
			if (!values.surname) {
				values.surname = '';
			}
			setValue(values);
		}
	}, [values]);
	useEffect(() => {
		if (clear) {
			setValue(null);
		}
	}, [clear]);
	function createNameSurname(e: any) {
		const name = e.target.value;
		setValue({ name, surname: '' });
		callback && callback({ name, surname: '' });
		return;
	}
	useEffect(() => {}, [value]);
	return (
		<div className={styles.select}>
			<div
				className={styles.select__head}
				onClick={() => setOpen(!open)}>
				{component === 'user' ? (
					<div className={styles.select__value}>
						{value
							? `${value.name} ${value.surname}`
							: `Velg ${context ? context : ''}`}
					</div>
				) : component === 'sja' ? (
					<div className={styles.select__value}>
						{value
							? `E${value.number}`
							: `Velg${context ? context : ''}`}
					</div>
				) : (
					<div className={styles.select__value}>
						{value ? value.name : `Velg ${context ? context : ''}`}
					</div>
				)}
			</div>
			{open && (
				<div className={styles.select__body}>
					{options.map((option) => {
						return (
							<div
								{...(option._id
									? { key: option._id }
									: { key: option.id })}
								className={styles.select__item}
								onClick={() => {
									setValue(option);
									setOpen(false);
									callback && callback(option);
								}}>
								{component === 'sja'
									? `E${option.number}`
									: component === 'user'
									? `${option.name} ${option.surname}`
									: `${option.name}`}
							</div>
						);
					})}
					{addnew && (
						<div
							className={`${styles.select__item} ${styles.select__new}`}
							onClick={() => {
								setModal(true);
								setOpen(false);
							}}>
							Leg til ny {context ? context : ''}
						</div>
					)}
					{addInput && (
						<div className={styles.select__item}>
							<input
								type="text"
								className={styles.select__input}
								value={value ? `${value.name}${value.surname}` : ''}
								onChange={(e) => {
									createNameSurname(e);
								}}
								onBlur={() => setOpen(false)}
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										setOpen(false);
									}
								}}
							/>
						</div>
					)}
				</div>
			)}
			<Modal
				isVisible={modal}
				title={`Leg til ny ${context ? context : ''}`}
				onClose={() => setModal(false)}
				{...(component === 'user'
					? {
							content: (
								<User
									type="new"
									callback={() => {
										setModal(false);
										callback && callback(value);
									}}
								/>
							),
					  }
					: {
							content: (
								<AddReference
									service={service}
									title={context ? context : ''}
									callback={() => {
										setModal(false);
										callback && callback(value);
									}}
								/>
							),
					  })}
				widthVh={'auto'}
				heightVh={'auto'}
			/>
		</div>
	);
};

export default observer(Select);
