import { useRef, useState, useEffect } from "react";
import useAutosizeTextArea from "./useAutosizeTextArea";
import "./TextArea.module.css";

interface IProps {
    onChange: (e: any) => void;
    placeholder?: string;
    value: string;
}

const TextArea = ({ onChange, placeholder, value }: IProps) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [value_current, setValueCurrent] = useState("");

    useAutosizeTextArea(textAreaRef.current, value_current);

    useEffect(() => {
        if(value) {
            setValueCurrent(value)
        }
    }, [value])
    const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        const val = evt.target?.value;

        setValueCurrent(val);
        onChange(val);
      };
    return (
        <textarea
            placeholder={placeholder}
            value={value_current}
            onChange={handleChange}
            rows={1}
            ref={textAreaRef}
        />
    );
};

export default TextArea;