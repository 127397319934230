import { FC, useContext, useState, useEffect } from 'react';
import { Context } from '../../../index';
import { observer } from 'mobx-react-lite';
import Input from '../../Input/Input';
import Button from '../../Button/Button';
import { IUser } from '../../../models/IUser';
import UserService from '../../../services/UserSevice';
import Select from '../../Input/Select';
import styles from './User.module.css';
import Modal from '../../Modal/Modal';
import UpdateOneValue from '../UpdateOneValue/UpdateOneValue';

interface IProps {
	type?: string;
	callback?: () => void;
	user?: IUser;
}
const Users: FC<IProps> = ({ type, callback, user }) => {
	const { store } = useContext(Context);
	const [name, setName] = useState<any>({ value: '', error: false });
	const [surname, setSurname] = useState<any>({ value: '', error: false });
	const [email, setEmail] = useState<any>({ value: '', error: false });
	const [password, setPassword] = useState<any>({ value: '', error: false });
	const [password_confirmation, setPasswordConfirmation] = useState<string>('');
	const [userType, setUserType] = useState<any>({
		value: '',
		error: false,
		name: '',
	});
	const [modalPassword, setModalPassword] = useState<any>({
		open: false,
		id: null,
	});
	useEffect(() => {
		if (localStorage.getItem('token')) {
			store.checkAuth();
		}
	}, []);
	useEffect(() => {}, [userType]);

	useEffect(() => {
		if (user) {
			setName({ value: user.name, error: false });
			setSurname({ value: user.surname, error: false });
			setEmail({ value: user.email, error: false });
			if (user.type === 1) {
				setUserType({ value: user.type, error: false, name: 'Dykker' });
			} else if (user.type === 2) {
				setUserType({ value: user.type, error: false, name: 'Dykkeleder' });
			} else if (user.type === 3) {
				setUserType({
					value: user.type,
					error: false,
					name: 'Dykkerassistent',
				});
			}
		}
	}, [user]);

	async function validating() {
		let valid = true;
		if (!name.value) {
			valid = false;
			setName({ ...name, error: true });
		}
		if (!surname.value) {
			valid = false;
			setSurname({ ...surname, error: true });
		}
		if (!email.value) {
			valid = false;
			setEmail({ ...email, error: true });
		}
		if (password.value !== password_confirmation) {
			valid = false;
			setPassword({ ...password, error: true });
		}
		if (!userType.value) {
			valid = false;
			setUserType({ ...userType, error: true });
		}
		return valid;
	}

	return (
		<div className={styles.form}>
			<div {...(userType.error ? { className: styles.error } : {})}>
				Bruker Type
			</div>
			<div {...(userType.error ? { className: styles.error } : {})}>
				<Select
					callback={(e: any) =>
						setUserType({ value: e.id, error: false, name: e.name })
					}
					options={[
						{ id: 1, name: 'Dykker' },
						{ id: 2, name: 'Dykkeleder' },
						{ id: 3, name: 'Dykkerassistent' },
						{ id: 4, name: 'SJA user only' },
					]}
					context="Bruker type"
					{...(type === 'edit'
						? {
								values: {
									id: userType.value,
									name: userType.name,
								},
						  }
						: {})}
				/>
			</div>
			<div {...(name.error ? { className: styles.error } : {})}>Navn</div>
			<div {...(name.error ? { className: styles.error } : {})}>
				<Input
					type="text"
					value={name.value}
					onchange={(e) => setName({ value: e.target.value, error: false })}
					classname="doubleForm"
				/>
			</div>
			<div {...(surname.error ? { className: styles.error } : {})}>
				Etternavn
			</div>
			<div {...(surname.error ? { className: styles.error } : {})}>
				<Input
					type="text"
					value={surname.value}
					onchange={(e) => setSurname({ value: e.target.value, error: false })}
					classname="doubleForm"
				/>
			</div>
			<div {...(email.error ? { className: styles.error } : {})}>Epost</div>
			<div {...(email.error ? { className: styles.error } : {})}>
				<Input
					type="text"
					value={email.value}
					classname="doubleForm"
					onchange={(e) => setEmail({ value: e.target.value, error: false })}
				/>
			</div>
			{type === 'edit' ? null : (
				<div {...(password.error ? { className: styles.error } : {})}>
					Passord
				</div>
			)}
			{type === 'edit' ? null : (
				<div {...(password.error ? { className: styles.error } : {})}>
					<Input
						type="password"
						value={password.value}
						classname="doubleForm"
						onchange={(e) =>
							setPassword({ value: e.target.value, error: false })
						}
					/>
				</div>
			)}
			{type === 'edit' ? null : <div>Bekreft passord</div>}
			{type === 'edit' ? null : (
				<div>
					<Input
						type="password"
						value={password_confirmation}
						classname="doubleForm"
						onchange={(e) => setPasswordConfirmation(e.target.value)}
					/>
				</div>
			)}

			<div className={styles.button}>
				<Button
					{...(type === 'edit' ? { text: 'Edit' } : { text: 'Opprett' })}
					{...(type === 'edit'
						? {
								onclick: () => {
									validating().then((valid) => {
										if (valid) {
											UserService.updateUser(
												user!.id,
												name.value,
												surname.value,
												email.value,
												userType.value
											).then((res) => {
												if (res.status === 200) {
													callback!();
												}
											});
										}
									});
								},
						  }
						: {
								onclick: () => {
									validating().then((valid) => {
										if (valid) {
											store.registration(
												name.value,
												surname.value,
												email.value,
												password.value,
												userType.value
											);
											callback && callback();
										}
									});
								},
						  })}
				/>
				{type === 'edit' ? (
					<Button
						text="Change Password"
						onclick={() => {
							setModalPassword({ open: true, id: user!.id });
						}}
					/>
				) : null}
				<Button
					text="Kanseller"
					onclick={() => {
						callback && callback();
					}}
					classname="red"
				/>
			</div>
			<Modal
				isVisible={modalPassword.open}
				title="Change Password"
				content={
					<UpdateOneValue
						id={modalPassword.id}
						field="password"
						service={UserService.updateOneField}
						callback={() => {
							setModalPassword({ open: false, id: null });
						}}
						type="password"
					/>
				}
				onClose={() => {
					setModalPassword({ open: false, id: null });
				}}
				widthVh={'auto'}
				heightVh={'auto'}
			/>
		</div>
	);
};

export default observer(Users);
