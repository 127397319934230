import $api from '../http';
import { AxiosResponse } from 'axios';

class WorkInstructionService {
	async submitWorkInstruction(
		data: FormData
	): Promise<AxiosResponse> {
		return await $api.post('/instruction/add', data);
	}
	async getAll(): Promise<AxiosResponse> {
		return await $api.get('/instruction/all');
	}
	async getOneByNumber(number: number): Promise<AxiosResponse> {
		return await $api.get(`/instruction/${number}`);
	}
	async getByClient(client: string): Promise<AxiosResponse> {
		return await $api.get(`/instruction/client/${client}`);
	}
}

export default new WorkInstructionService();
