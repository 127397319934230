import { FC, useContext, useState, useEffect } from 'react';
import { Context } from '../../../index';
import { observer } from 'mobx-react-lite';
import Button from '../../Button/Button';
import UserService from '../../../services/UserSevice';
import styles from './User.module.css';

interface IProps {
	user: string;
	callback?: () => void;
}

const Delete: FC<IProps> = ({ user, callback }) => {
	const { store } = useContext(Context);
	return (
		<div className={styles.button}>
			<Button
				text="Slett"
				onclick={() => {
					UserService.deleteUser(user);
					callback && callback();
				}}
				classname="red"
			/>
			<Button
				text="Kanseller"
				onclick={() => {
					callback && callback();
				}}
			/>
		</div>
	);
};

export default observer(Delete);
