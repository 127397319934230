import $api from '../http';
import { AxiosResponse } from 'axios';

export default class MailService {
	static async sendMail(
		email: string,
		number: number
	): Promise<AxiosResponse> {
		return $api.post('/email/send', { email, number });
	}
	static async sendInstruction(
		email: string,
		number: number
	): Promise<AxiosResponse> {
		return $api.post('/email/instruction', { email, number });
	}
	static async resendEmail(
		number: number
	): Promise<AxiosResponse> {
		return $api.post('/email/resend', { number });
	}
}
