import { FC, useState, useEffect, useLayoutEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './SJA.module.css';
import TextArea from '../../component/Input/TextArea';
import Input from '../../component/Input/Input';
import Select from '../../component/Input/Select';
import UserService from '../../services/UserSevice';
import { IUser } from '../../models/IUser';

interface IProps {
	values?: [
		{
			name: any;
			company: any;
			date: any;
			signature: any;
		}
	];
	callback: (values: any) => void;
}

const SJABottom: FC<IProps> = ({ values, callback }) => {
	const [work_values, setWorkValues] = useState<any>([]);
	const [users, setUsers] = useState<IUser[]>([]);
	useLayoutEffect(() => {
		getUsers();
	}, []);
	useEffect(() => {
		if (values) {
			setWorkValues(values);
		}
	}, [values]);

	useLayoutEffect(() => {
		callback(work_values);
	}, [work_values]);

	function addRow() {
		const new_row = {
			name: { value: '', error: false },
			company: { value: '', error: false },
			date: { value: '', error: false },
			signature: { value: '', error: false },
		};
		setWorkValues([...work_values, new_row]);
	}
	function removeRow() {
		if (work_values.length > 0) {
			setWorkValues(work_values.slice(0, work_values.length - 1));
		}
	}
	async function getUsers() {
		const users = await UserService.fetchUsers();
		setUsers(users.data);
	}
	function changeName(value: any, index: number) {
		const new_work_values = [...work_values];
		new_work_values[index].name = {
			value: value.name + ' ' + value.surname,
			error: false,
		};
		setWorkValues(new_work_values);
	}
	function changeCompany(value: any, index: number) {
		const new_work_values = [...work_values];
		new_work_values[index].company = { value: value, error: false };
		setWorkValues(new_work_values);
	}
	function changeDate(value: any, index: number) {
		const new_work_values = [...work_values];
		new_work_values[index].date = { value: value, error: false };
		setWorkValues(new_work_values);
	}
	function changeSignature(value: any, index: number) {
		const new_work_values = [...work_values];
		new_work_values[index].signature = { value: value, error: false };
		setWorkValues(new_work_values);
	}
	return (
		<>
			{work_values.map((item: any, index: number) => {
				return (
					<>
						<div
							{...(item.name.error
								? { className: `${styles.form_item} ${styles.error}` }
								: { className: styles.form_item })}
						>
							<Select
								options={users}
								callback={(value: string) => changeName(value, index)}
								component="user"
								addInput={true}
								{...(item.name.value
									? { values: { name: item.name.value } }
									: null)}
							/>
						</div>
						<div
							{...(item.company.error
								? { className: `${styles.form_item} ${styles.errorInput}` }
								: { className: styles.form_item })}
						>
							<Input
								type="text"
								value={item.company.value}
								onchange={(e: any) => changeCompany(e.target.value, index)}
							/>
						</div>
						<div
							{...(item.date.error
								? { className: `${styles.form_item} ${styles.errorInput}` }
								: { className: styles.form_item })}
						>
							<Input
								type="date"
								value={item.date.value}
								onchange={(e: any) => changeDate(e.target.value, index)}
							/>
						</div>
						<div className={styles.form_item}>
							<Input
								type="text"
								value={item.signature.value}
								onchange={(e: any) => changeSignature(e.target.value, index)}
							/>
						</div>
					</>
				);
			})}
			<div className={`${styles.form_item} ${styles.form_instrument}`}>
				<span onClick={addRow}>+</span>
				<span onClick={removeRow}>-</span>
			</div>
		</>
	);
};

export default observer(SJABottom);
