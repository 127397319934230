import { FC, useContext, useState, useEffect } from "react";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import Button from "../../component/Button/Button";
import UserService from "../../services/UserSevice";
import ReferencesService from "../../services/ReferencesService";
import MailService from "../../services/MailService";

interface IProps {
    journal: any;
}

const JournalList: FC<IProps> = ({ journal }) => {
    const { store } = useContext(Context);
    const [date, setDate] = useState<string>('');
    const [lider, setLider] = useState<string>('');
    const [diver1, setDiver1] = useState<string>('');
    const [diver2, setDiver2] = useState<string>('');
    const [assistent, setAssistent] = useState<string>('');
    const [dive_method, setDive_method] = useState<string>('');


    useEffect(() => {
        if (journal) {
            getOneUser(journal.lider.id).then(data => {
                setLider(data);
            });
            getOneUser(journal.diver1.id).then(data => {
                setDiver1(data);
            });
            getOneUser(journal.diver2.id).then(data => {
                setDiver2(data);
            });
            getOneUser(journal.assistent.id).then(data => {
                setAssistent(data);
            });
            getOneDiveMethod(journal.dive_method).then(data => {
                setDive_method(data);
            });
            setDate(dateToString(journal.date));
        }
    }, [journal]);

    async function getOneUser(id: string) {
        let response = await UserService.getOne(id);
        let name = response.data.name + " " + response.data.surname;
        return name;
    }
    async function getOneDiveMethod(id: string) {
        let response = await ReferencesService.getOneDiveMethod(id);
        return response.data.name;
    }
    function dateToString(date: string) {
        let newDate = new Date(date);
        let year = String(newDate.getFullYear());
        let month = String(newDate.getMonth() + 1).padStart(2, '0');
        let day = String(newDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    return (
        <tr key={journal._id}>
            <td>{journal.number}</td>
            <td>{date}</td>
            <td>{lider}</td>
            <td>{diver1}</td>
            <td>{diver2}</td>
            <td>{assistent}</td>
            <td>{dive_method}</td>
            <td>{journal.client}</td>
            <td>{journal.diver1_info.at_the_bottom.max_time}</td>
            <td>{journal.diver2_info.at_the_bottom.max_time ? journal.diver2_info.at_the_bottom.max_time : ''}</td>
            <td>{journal.diver1_info.usage_table.metter}</td>
            <td>{journal.diver2_info.usage_table.metter ? journal.diver2_info.usage_table.metter : ''}</td>
            <td><Button
                onclick={() => window.open(`./pdf/journal_${journal.number}.pdf`)}
                text="Til PDF"
            />
            </td>
            <td><Button
                onclick={() => MailService.resendEmail(journal.number)}
                text="ReSend Dykkejournal"
            />
            </td>
        </tr>
    );
};

export default observer(JournalList);