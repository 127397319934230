import { FC, useContext, useState, useEffect } from 'react';
import { Context } from '../../..';
import { observer } from 'mobx-react-lite';
import Button from '../../Button/Button';
import SJAService from '../../../services/SJAService';
import styles from './TempSJA.module.css';
interface IProps {
	callback: (e: any) => void;
}

const TempSJA: FC<IProps> = ({ callback }) => {
	const store = useContext(Context);
	const [sjaList, setSjaList] = useState<any[]>([]);
	const [sja, setSja] = useState<any>({});

	useEffect(() => {
		getSja();
	}, []);

	async function getSja() {
		const response = await SJAService.getEndedSja();
		setSjaList(response.data);
	}
	function dateToString(date: string) {
		let year = String(new Date(date).getFullYear());
		let month = String(new Date(date).getMonth() + 1).padStart(
			2,
			'0'
		);
		let day = String(new Date(date).getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
	}
	function choise(e: any) {
		setSja(e);
		callback(e);
	}
	return (
		<>
			<div>
				<table>
					<tbody>
						<tr>
							<td>SJA Nr.</td>
							<td>Prosjekt navn</td>
							<td>Dato</td>
							<td>Use as Template</td>
						</tr>
						{sjaList.map((item: any, index: number) => {
							return (
								<tr key={item._id} className={styles.tr}>
									<td>{`S-${item.number}`}</td>
									<td>{item.name}</td>
									<td>{dateToString(item.date)}</td>
									<td>
										{
											<Button
												text="Use it"
												callback={() => choise(item)}
											/>
										}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</>
	);
};

export default observer(TempSJA);
