import { FC, useContext, useState, useEffect } from 'react';
import { Context } from '../../index';
import { observer } from 'mobx-react-lite';
import { IUser } from '../../models/IUser';
import UserService from '../../services/UserSevice';
import Modal from '../../component/Modal/Modal';
import Button from '../../component/Button/Button';
import User from '../../component/Forms/User/User';
import styles from './Users.module.css';
import Delete from '../../component/Forms/User/Delete';

const Users: FC = () => {
	const { store } = useContext(Context);
	const [users, setUsers] = useState<IUser[]>([]);
	const [reload, setReload] = useState<boolean>(false);
	const [modalNewUser, setModalNewUser] = useState<boolean>(false);
	const [modalEditUser, setModalEditUser] = useState<any>({
		open: false,
		user: null,
	});
	const [modalDeleteUser, setModalDeleteUser] = useState<any>({
		open: false,
		user: null,
		name: null,
	});

	useEffect(() => {
		if (localStorage.getItem('token')) {
			store.checkAuth();
		}
	}, []);

	useEffect(() => {
		if (store.isAuth) {
			getUsers();
			let timer = setInterval(() => {
				reloadPage();
			}, 1000);
			if (users.length > 0) {
				clearInterval(timer);
				getUsers();
			}
			return () => {
				clearInterval(timer);
			};
		}
	}, [reload]);

	function reloadPage() {
		setReload(!reload);
	}
	async function getUsers() {
		try {
			const response = await UserService.fetchUsers();
			setUsers(response.data);
		} catch (e) {
			console.log(e);
		}
	}
	return (
		<>
			{store.isAuth ? (
				<div>
					<h1>Brukere</h1>
					{store.isAuth ? (
						<div>
							<Button
								text="Ny bruker"
								onclick={() => {
									setModalNewUser(true);
								}}
							/>
							<div className={styles.userForm}>
								{users.map((user) => {
									let type = '';
									if (user.type == 1) {
										type = 'Dykker';
									} else if (user.type == 2) {
										type = 'Dykkeleder';
									} else if (user.type == 3) {
										type = 'Dykkerassistent';
									}
									return (
										<div className={styles.userForm_item} key={user.id}>
											<div>
												{user.name} {user.surname}
											</div>
											<div>{user.email}</div>
											<div>{type}</div>
											<div>
												<Button
													text="Edit"
													onclick={() => {
														setModalEditUser({ open: true, user: user });
													}}
												/>
											</div>
											<div>
												<Button
													text="Slett"
													onclick={() => {
														setModalDeleteUser({
															open: true,
															user: user.id,
															name: `${user.name} ${user.surname}`,
														});
														setReload(!reload);
													}}
													classname="red"
												/>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					) : null}
					<Modal
						isVisible={modalNewUser}
						title="New User"
						onClose={() => setModalNewUser(false)}
						content={
							<User
								type="new"
								callback={() => {
									setModalNewUser(false);
									setReload(!reload);
								}}
							/>
						}
						widthVh={'50vw'}
						heightVh={'auto'}
					/>
					<Modal
						isVisible={modalEditUser.open}
						title="Edit User"
						onClose={() => setModalEditUser({ open: false, user: null })}
						content={
							<User
								type="edit"
								callback={() => {
									setModalEditUser({ open: false, user: null });
									setReload(!reload);
								}}
								user={modalEditUser.user}
							/>
						}
						widthVh={'50vw'}
						heightVh={'auto'}
					/>
					<Modal
						isVisible={modalDeleteUser.open}
						title={`Slett Bruker ${modalDeleteUser.name}`}
						onClose={() =>
							setModalDeleteUser({ open: false, user: null, name: null })
						}
						content={
							<Delete
								user={modalDeleteUser.user}
								callback={() => {
									setModalDeleteUser({ open: false, user: null, name: null });
									setReload(!reload);
								}}
							/>
						}
						widthVh={'30vw'}
						heightVh={'auto'}
					/>
				</div>
			) : null}
		</>
	);
};

export default observer(Users);
