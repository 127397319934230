import axios from 'axios';
import { AuthResponse } from '../models/response/AuthResponse';

// TODO api url
// export const API_URL = 'http://localhost:5000/api'; // local
export const API_URL = 'http://109.203.114.194:5000/api'; //prode

const $api = axios.create({
	withCredentials: true,
	baseURL: API_URL,
});

$api.interceptors.request.use((config) => {
	if (config.headers !== undefined) {
		config.headers.Authorization = `Bearer ${localStorage.getItem(
			'token'
		)}`;
	}
	return config;
});

$api.interceptors.response.use(
	(config) => {
		return config;
	},
	async (error) => {
		const originalRequest = error.config;
		if (
			error.response.status === 401 &&
			error.config &&
			!error.config._isRetry
		) {
			originalRequest._isRetry = true;
			try {
				const response = await axios.get<AuthResponse>(
					`${API_URL}/refresh`,
					{
						withCredentials: true,
					}
				);
				localStorage.setItem('token', response.data.accessToken);
				return $api.request(originalRequest);
			} catch (e: any) {
				console.log(e);
			}
		}
		throw error;
	}
);

export default $api;
