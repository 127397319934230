export class SJAMiddleDTO {
	id: number;
	activity: string;
	moment_of_danger: string;
	cause: string;
	risk_evoluation: Object;
	risk_reducing_measures: string;
	risk_after_measures: Object;
	comments: string;
	constructor(model: any) {
		this.id = model.id.value;
		this.activity = model.activity.value;
		this.moment_of_danger = model.moment_of_danger.value;
		this.cause = model.cause.value;
		this.risk_evoluation = {
			s: Number(model.risk_evoluation.s.value),
			k: Number(model.risk_evoluation.k.value),
			r: Number(model.risk_evoluation.r.value),
		};
		this.risk_reducing_measures = model.risk_reducing_measures.value;
		this.risk_after_measures = {
			s: Number(model.risk_after_measures.s.value),
			k: Number(model.risk_after_measures.k.value),
			r: Number(model.risk_after_measures.r.value),
		};
		this.comments = model.comments.value;
	}
}
