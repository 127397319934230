import { FC, useContext, useState, useLayoutEffect } from 'react';
import { Context } from '../../../index';
import { observer } from 'mobx-react-lite';
import Input from '../../Input/Input';
import Button from '../../Button/Button';
import styles from './UpdateReverence.module.css';

interface IProps {
	id: string;
	field: string;
	service: (id: string, value: string) => Promise<any>;
	callback?: () => void;
	type: string;
	values: string;
}

const UpdateReference: FC<IProps> = ({
	id,
	field,
	service,
	type,
	callback,
	values,
}) => {
	const { store } = useContext(Context);
	const [value, setValue] = useState<string>('');

	useLayoutEffect(() => {
		if (values) {
			setValue(values);
		}
	}, []);
	return (
		<div className={styles.form}>
			<div>
				<Input
					type={type}
					placeholder={field}
					value={value}
					onchange={(e) => setValue(e.target.value)}
				/>
			</div>
			<div>
				<Button
					text="Save"
					onclick={() => {
						service(id, value);
						callback && callback();
					}}
				/>
			</div>
		</div>
	);
};

export default observer(UpdateReference);
