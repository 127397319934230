export class SJABottomReturnDTO {
    name: Object;
    company: Object;
    date: Object;
    signature: Object;
    constructor(model: any) {
        this.name = {
            value: model.name
        };
        this.company = {
            value: model.company
        };
        this.date = {
            value: dateToString(model.date)
        };
        this.signature = {
            value: model.signature
        };
    }
}

function dateToString(date: string) {
    let year = String(new Date(date).getFullYear());
    let month = String(new Date(date).getMonth() + 1).padStart(2, "0");
    let day = String(new Date(date).getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
}