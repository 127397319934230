import { FC, useContext, useState } from 'react';
import { Context } from '../../../index';
import { observer } from 'mobx-react-lite';
import Input from '../../Input/Input';
import Button from '../../Button/Button';

interface IProps {
	id: string;
	field: string;
	service: (id: string, field: string, value: string) => Promise<any>;
	callback?: () => void;
	type: string;
}

const UpdateOneValue: FC<IProps> = ({
	id,
	field,
	service,
	type,
	callback,
}) => {
	const { store } = useContext(Context);
	const [value, setValue] = useState<string>('');
	return (
		<div>
			<Input
				type={type}
				placeholder={field}
				value={value}
				onchange={(e) => setValue(e.target.value)}
			/>
			<Button
				text="Save"
				onclick={() => {
					service(id, field, value);
					callback && callback();
				}}
			/>
		</div>
	);
};

export default observer(UpdateOneValue);
