export class SJAMiddleReturnDto{
    id: Object;
    activity: Object;
    moment_of_danger: Object;
    cause: Object;
    risk_evoluation: Object;
    risk_reducing_measures: Object;
    risk_after_measures: Object;
    comments: Object;
    constructor(model: any) {
        this.id = {
            value: model.id,
            error: false
        };
        this.activity = {
            value: model.activity,
            error: false
        };
        this.moment_of_danger = {
            value: model.moment_of_danger,
            error: false
        };
        this.cause = {
            value: model.cause,
            error: false
        };
        this.risk_evoluation = {
            s: {value: model.risk_evoluation.s},
            k: {value: model.risk_evoluation.k},
            r: {value: model.risk_evoluation.r},
            error: false
        };
        this.risk_reducing_measures = {
            value: model.risk_reducing_measures,
            error: false
        };
        this.risk_after_measures = {
            s: {value: model.risk_after_measures.s},
            k: {value: model.risk_after_measures.k},
            r: {value: model.risk_after_measures.r},
            error: false
        };
        this.comments = {
            value: model.comments,
            error: false
        };

    }
}