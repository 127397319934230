import { FC, useContext, useState, useEffect } from 'react';
import { Context } from '../../index';
import { observer } from 'mobx-react-lite';
import styles from './References.module.css';
import Input from '../../component/Input/Input';
import Button from '../../component/Button/Button';
import Select from '../../component/Input/Select';
import Modal from '../../component/Modal/Modal';
import ReferencesService from '../../services/ReferencesService';
import UpdateReference from '../../component/Forms/UpdateReference/UpdateReference';
import DeleteReference from '../../component/Forms/UpdateReference/DeleteReference';

const References: FC = () => {
	const { store } = useContext(Context);
	const [referenceType, setReferenceType] = useState<string>('');
	const [sjaRiskMatrix, setSjaRiskMatrix] = useState<any>({ file: '' });
	const [sjaRiskMatrixButtonText, setSjaRiskMatrixButtonText] =
		useState<string>('Upload');
	const [methods, setMethods] = useState<any[]>([]);
	const [equipments, setEquipments] = useState<any[]>([]);
	const [aires, setAires] = useState<any[]>([]);
	const [modalView, setModalView] = useState<any>({
		title: '',
		callback: '',
		visible: false,
	});
	const [modalDelete, setModalDelete] = useState<any>({
		visible: false,
	});
	useEffect(() => {
		if (localStorage.getItem('token')) {
			store.checkAuth();
		}
	}, []);
	const hanleChange = (e: any) => {
		setSjaRiskMatrix({ file: e.target.files[0] });
	};
	async function uploadSjaRiskMatrix() {
		const responce = await ReferencesService.upload(sjaRiskMatrix.file);
		if (responce.status === 200) {
			setSjaRiskMatrixButtonText('Uploaded');
			setTimeout(() => {
				setSjaRiskMatrixButtonText('Upload');
				setSjaRiskMatrix(null);
			}, 1000);
		}
	}
	async function getAllMethod() {
		const responce = await ReferencesService.getDiveMethods();
		if (responce.status === 200) {
			setMethods(responce.data);
		}
	}
	async function getAllEquip() {
		const responce = await ReferencesService.getEquipments();
		if (responce.status === 200) {
			setEquipments(responce.data);
		}
	}
	async function getAllAir() {
		const responce = await ReferencesService.getBreathingMixes();
		if (responce.status === 200) {
			setAires(responce.data);
		}
	}

	return (
		<div>
			<div className={styles.choise}>
				<div
					{...(referenceType === 'method'
						? { className: styles.active }
						: null)}
					onClick={() => {
						getAllMethod();
						setReferenceType('method');
					}}
				>
					Dykkemetode
				</div>
				<div
					{...(referenceType === 'equip' ? { className: styles.active } : null)}
					onClick={() => {
						getAllEquip();
						setReferenceType('equip');
					}}
				>
					Utstyr
				</div>
				<div
					{...(referenceType === 'air' ? { className: styles.active } : null)}
					onClick={() => {
						getAllAir();
						setReferenceType('air');
					}}
				>
					Pustegass
				</div>
				<div
					{...(referenceType === 'sja' ? { className: styles.active } : null)}
					onClick={() => setReferenceType('sja')}
				>
					SJA
				</div>
			</div>
			{referenceType === 'method' ? (
				<div className={styles.reference}>
					<div>ID</div>
					<div>Name</div>
					<div></div>

					{methods.map((item: any, index: number) => {
						return (
							<>
								<div>{index + 1}</div>
								<div>{item.name}</div>
								<div>
									<Button
										text="Edit"
										callback={() => {
											setModalView({
												id: item._id,
												service: ReferencesService.updateOneDiveMethod,
												callback: getAllMethod,
												visible: true,
												title: 'Dykkemetode',
												value: item.name,
											});
										}}
									/>
									<Button
										text="Slett"
										callback={() => {
											setModalDelete({
												visible: true,
												id: item._id,
												service: ReferencesService.deleteOneMethod,
												callback: getAllMethod,
											});
										}}
										classname="red"
									/>
								</div>
							</>
						);
					})}
				</div>
			) : null}
			{referenceType === 'equip' ? (
				<div className={styles.reference}>
					<div>ID</div>
					<div>Name</div>
					<div></div>

					{equipments.map((item: any, index: number) => {
						return (
							<>
								<div>{index + 1}</div>
								<div>{item.name}</div>
								<div>
									<Button
										text="Edit"
										callback={() => {
											setModalView({
												id: item._id,
												service: ReferencesService.udateOneEquip,
												callback: getAllEquip,
												visible: true,
												title: 'Utstyr',
												value: item.name,
											});
										}}
									/>
									<Button
										text="Slett"
										callback={() => {
											setModalDelete({
												visible: true,
												id: item._id,
												service: ReferencesService.deleteOneEquip,
												callback: getAllEquip,
											});
										}}
										classname="red"
									/>
								</div>
							</>
						);
					})}
				</div>
			) : null}
			{referenceType === 'air' ? (
				<div className={styles.reference}>
					<div>ID</div>
					<div>Name</div>
					<div></div>

					{aires.map((item: any, index: number) => {
						return (
							<>
								<div>{index + 1}</div>
								<div>{item.name}</div>
								<div>
									<Button
										text="Edit"
										callback={() => {
											setModalView({
												id: item._id,
												service: ReferencesService.udateOneBreathingMix,
												callback: getAllAir,
												visible: true,
												title: 'Pustegass',
												value: item.name,
											});
										}}
									/>
									<Button
										text="Slett"
										callback={() => {
											setModalDelete({
												visible: true,
												id: item._id,
												service: ReferencesService.deleteOneBreathingMix,
												callback: getAllEquip,
											});
										}}
										classname="red"
									/>
								</div>
							</>
						);
					})}
				</div>
			) : null}
			{referenceType === 'sja' ? (
				<div className={styles.sjaUpload}>
					<div>
						<Input type="file" onchange={(e: any) => hanleChange(e)} />
					</div>
					<div>
						<Button
							text={sjaRiskMatrixButtonText}
							callback={uploadSjaRiskMatrix}
						/>
					</div>
				</div>
			) : null}
			<Modal
				isVisible={modalView.visible}
				title={modalView.title}
				content={
					<UpdateReference
						id={modalView.id}
						field="name"
						service={modalView.service}
						callback={() => {
							setModalView({
								id: null,
								service: null,
								callback: null,
								visible: false,
								title: null,
							});
							modalView.callback();
						}}
						type="text"
						values={modalView.value}
					/>
				}
				onClose={() =>
					setModalView({
						id: null,
						service: null,
						callback: null,
						visible: false,
						title: null,
					})
				}
			/>
			<Modal
				isVisible={modalDelete.visible}
				title="Are you sure?"
				content={
					<DeleteReference
						id={modalDelete.id}
						callback={() => {
							setModalDelete({
								id: null,
								service: null,
								callback: null,
								visible: false,
							});
							modalDelete.callback();
						}}
						service={modalDelete.service}
					/>
				}
				onClose={() =>
					setModalDelete({
						id: null,
						service: null,
						callback: null,
						visible: false,
					})
				}
			/>
		</div>
	);
};

export default observer(References);
