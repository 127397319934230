import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './Button.module.css';

export interface Props {
	type?: 'button' | 'submit' | 'reset';
	text: string;
	classname?: string;
	onclick?: () => void;
	callback?: () => void;
	disabled?: boolean;
}

const Button: FC<Props> = ({
	type = 'button',
	text,
	onclick,
	callback,
	classname,
	disabled,
}) => {
	function click() {
		onclick && onclick();
		callback && callback();
	}
	return (
		<button
			type={type}
			onClick={click}
			{...(disabled
				? {
						className: `${styles.button} ${styles.disabled} ${
							styles[classname || '']
						}`,
				  }
				: {
						className: `${styles.button} ${styles[classname || '']}`,
				  })}
			disabled={disabled}>
			{text}
		</button>
	);
};

export default observer(Button);
