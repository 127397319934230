import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './Input.module.css';

interface IProps {
	htmlFor: string;
	label: string;
	type: string;
	id: string;
	register: any;
	errors: any;
}
const Input: FC<IProps> = ({
	htmlFor,
	label,
	type,
	id,
	register,
	errors,
}) => {
	const [err, setErr] = React.useState(false);
	useEffect(() => {
		if (errors[htmlFor]) {
			setErr(true);
		} else {
			setErr(false);
		}
	}, [errors[htmlFor]]);
	return (
		<div
			{...(err
				? {
						className: `${styles.instructionElement} ${styles.error}`,
				  }
				: { className: styles.instructionElement })}
			onClick={() => {
				setErr(false);
				errors[htmlFor] = false;
			}}>
			<label htmlFor={htmlFor}>{label}</label>
			<textarea id={id} {...register(id)} className={styles.input} />
		</div>
	);
};

export default observer(Input);
