import $api from '../http';
import { AxiosResponse } from 'axios';

export default class SJAService {
	static async createSJA(
		name: string,
		client: string,
		sdi_user_responsible: string,
		location: string,
		date: string,
		client_user_responsible: string,
		doc_reference: string,
		form_middle: any,
		form_bottom: any
	): Promise<AxiosResponse> {
		const status = 1;
		return $api.post('/sja/add', {
			name,
			client,
			sdi_user_responsible,
			location,
			date,
			client_user_responsible,
			doc_reference,
			form_middle,
			form_bottom,
			status,
		});
	}
	static async createSJAtoEdit(
		name: string,
		client: string,
		sdi_user_responsible: string,
		location: string,
		date: string,
		client_user_responsible: string,
		doc_reference: string,
		form_middle: any,
		form_bottom: any,
		status?: number
	): Promise<AxiosResponse> {
		if (!status) status = 2;
		return $api.post('/sja/add', {
			name,
			client,
			sdi_user_responsible,
			location,
			date,
			client_user_responsible,
			doc_reference,
			form_middle,
			form_bottom,
			status,
		});
	}
	static async getEditSja(): Promise<AxiosResponse<any>> {
		return $api.get<any>('/sja/edit/');
	}
	static async getEndedSja(): Promise<AxiosResponse<any>> {
		return $api.get<any>('/sja/ended/');
	}
	static async updateSJAtoEdit(
		id: string,
		number: string,
		name: string,
		client: string,
		sdi_user_responsible: string,
		location: string,
		date: string,
		client_user_responsible: string,
		doc_reference: string,
		form_middle: any,
		form_bottom: any,
		status?: number
	): Promise<AxiosResponse> {
		if (!status) status = 2;
		return $api.post(`/sja/update/${id}`, {
			number,
			name,
			client,
			sdi_user_responsible,
			location,
			date,
			client_user_responsible,
			doc_reference,
			form_middle,
			form_bottom,
			status,
		});
	}
	static async updateSJA(
		id: string,
		number: string,
		name: string,
		client: string,
		sdi_user_responsible: string,
		location: string,
		date: string,
		client_user_responsible: string,
		doc_reference: string,
		form_middle: any,
		form_bottom: any,
		status?: number
	): Promise<AxiosResponse> {
		if (!status) status = 2;
		return $api.post(`/sja/updateSJA/${id}`, {
			number,
			name,
			client,
			sdi_user_responsible,
			location,
			date,
			client_user_responsible,
			doc_reference,
			form_middle,
			form_bottom,
			status,
		});
	}
	static async deleteOne(id: string): Promise<AxiosResponse> {
		return $api.delete(`sja/${id}`);
	}
	static async searchByText(search: string): Promise<AxiosResponse> {
		return $api.post('/sja/search', { search });
	}
	static async getOne(id: string): Promise<AxiosResponse> {
		return $api.get(`/sja/${id}`);
	}
	static async setVoid(id: string): Promise<AxiosResponse> {
		return $api.post(`/sja/voided/${id}`);
	}
}
