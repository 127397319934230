import $api from '../http';
import { AxiosResponse } from 'axios';

export default class PDFService {
	static async createPDF(
		number: number
	): Promise<AxiosResponse<any>> {
		return $api.post<any>(`/pdf/`, { number });
	}
	static async createSjaPdf(
		number: number
	): Promise<AxiosResponse<any>> {
		return $api.post<any>(`/pdf/sja`, { number });
	}
	static async createInstructionPdf(
		number: number
	): Promise<AxiosResponse<any>> {
		return $api.post<any>(`/pdf/instruction`, { number });
	}
}
