export class SJABottomDTO {
    name: string;
    company: string;
    date: string;
    signature: string;
    constructor(model: any) {
        this.name = model.name.value;
        this.company = model.company.value;
        this.date = model.date.value;
        this.signature = model.signature.value;
    }
}