import { FC, useState, useEffect, useLayoutEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './SJA.module.css';
import TextArea from '../../component/Input/TextArea';

interface IProps {
	values?: [
		{
			id: number;
			activity: any;
			moment_of_danger: any;
			cause: any;
			risk_evoluation: {
				s: any;
				k: any;
				r: any;
			};
			risk_reducing_measures: any;
			risk_after_measures: {
				s: any;
				k: any;
				r: any;
			};
			comments: any;
		}
	];
	callback: (values: any) => void;
}

const SJAMidle: FC<IProps> = ({ values, callback }) => {
	const [work_values, setWorkValues] = useState<any>([]);

	useEffect(() => {
		if (values) {
			setWorkValues(values);
		}
	}, [values]);

	useLayoutEffect(() => {
		callback(work_values);
	}, [work_values]);

	function addRow() {
		const new_row = {
			id: { value: work_values.length + 1 },
			activity: { value: '', error: false },
			moment_of_danger: { value: '', error: false },
			cause: { value: '', error: false },
			risk_evoluation: {
				s: { value: 0, error: false },
				k: { value: 0, error: false },
				r: { value: 0, error: false },
			},
			risk_reducing_measures: { value: '', error: false },
			risk_after_measures: {
				s: { value: 0, error: false },
				k: { value: 0, error: false },
				r: { value: 0, error: false },
			},
			comments: { value: '', error: false },
		};
		setWorkValues([...work_values, new_row]);
	}
	function removeRow() {
		if (work_values.length > 0) {
			setWorkValues(work_values.slice(0, work_values.length - 1));
		}
	}

	function onChangeActivity(value: string, index: number) {
		const new_work_values = [...work_values];
		new_work_values[index].activity = { value: value, error: false };
		setWorkValues(new_work_values);
	}
	function onChangeMomentOfDanger(value: string, index: number) {
		const new_work_values = [...work_values];
		new_work_values[index].moment_of_danger = { value: value, error: false };
		setWorkValues(new_work_values);
	}
	function onChangeCause(value: string, index: number) {
		const new_work_values = [...work_values];
		new_work_values[index].cause = { value: value, error: false };
		setWorkValues(new_work_values);
	}
	function onChangeRiskReducingMeasures(value: string, index: number) {
		const new_work_values = [...work_values];
		new_work_values[index].risk_reducing_measures = {
			value: value,
			error: false,
		};
		setWorkValues(new_work_values);
	}
	function onChangeComments(value: string, index: number) {
		const new_work_values = [...work_values];
		new_work_values[index].comments = { value: value, error: false };
		setWorkValues(new_work_values);
	}
	function onChangeRiskEvoluationS(value: string, index: number) {
		const new_work_values = [...work_values];
		new_work_values[index].risk_evoluation.s = { value: value, error: false };
		setWorkValues(new_work_values);
		countRiskR(index);
	}
	function onChangeRiskEvoluationK(value: string, index: number) {
		const new_work_values = [...work_values];
		new_work_values[index].risk_evoluation.k = { value: value, error: false };
		setWorkValues(new_work_values);
		countRiskR(index);
	}
	function countRiskR(index: number) {
		if (
			work_values[index].risk_evoluation.s &&
			work_values[index].risk_evoluation.k
		) {
			const new_work_values = [...work_values];
			new_work_values[index].risk_evoluation.r = {
				value:
					new_work_values[index].risk_evoluation.s.value *
					new_work_values[index].risk_evoluation.k.value,
				error: false,
			};
			setWorkValues(new_work_values);
		}
	}
	function onChangeRiskAfterMeasuresS(value: string, index: number) {
		const new_work_values = [...work_values];
		new_work_values[index].risk_after_measures.s = {
			value: value,
			error: false,
		};
		setWorkValues(new_work_values);
		countRiskAfterMeasuresR(index);
	}
	function onChangeRiskAfterMeasuresK(value: string, index: number) {
		const new_work_values = [...work_values];
		new_work_values[index].risk_after_measures.k = {
			value: value,
			error: false,
		};
		setWorkValues(new_work_values);
		countRiskAfterMeasuresR(index);
	}
	function countRiskAfterMeasuresR(index: number) {
		if (
			work_values[index].risk_after_measures.s &&
			work_values[index].risk_after_measures.k
		) {
			const new_work_values = [...work_values];
			new_work_values[index].risk_after_measures.r = {
				value:
					new_work_values[index].risk_after_measures.s.value *
					new_work_values[index].risk_after_measures.k.value,
				error: false,
			};
			setWorkValues(new_work_values);
		}
	}

	return (
		<>
			{work_values.map((item: any, index: number) => {
				return (
					<>
						<div className={styles.form_item}>{index + 1}</div>
						<div className={styles.form_item}>
							<TextArea
								value={item.activity.value}
								onChange={(e: any) => onChangeActivity(e, index)}
							/>
						</div>
						<div className={styles.form_item}>
							<TextArea
								value={item.moment_of_danger.value}
								onChange={(e: any) => onChangeMomentOfDanger(e, index)}
							/>
						</div>
						<div className={styles.form_item}>
							<TextArea
								value={item.cause.value}
								onChange={(e: any) => onChangeCause(e, index)}
							/>
						</div>
						<div className={`${styles.form_item} ${styles.risk_title}`}>
							<div className={styles.risk}>
								<div
									{...(item.risk_evoluation.s.error
										? { className: styles.errorInput }
										: null)}
								>
									<input
										type="number"
										value={item.risk_evoluation.s.value}
										onChange={(e: any) =>
											onChangeRiskEvoluationS(e.target.value, index)
										}
										className={styles.form_input}
									/>
								</div>
								<div
									{...(item.risk_evoluation.k.error
										? { className: styles.errorInput }
										: null)}
								>
									<input
										type="number"
										value={item.risk_evoluation.k.value}
										onChange={(e: any) =>
											onChangeRiskEvoluationK(e.target.value, index)
										}
										className={styles.form_input}
									/>
								</div>
								<div
									{...(item.risk_evoluation.r.value === 0
										? null
										: item.risk_evoluation.r.value < 6
										? { className: styles.risk_green }
										: item.risk_evoluation.r.value < 15
										? { className: styles.risk_yellow }
										: { className: styles.risk_red })}
								>
									{item.risk_evoluation.r.value}
								</div>
							</div>
						</div>
						<div className={styles.form_item}>
							<TextArea
								value={item.risk_reducing_measures.value}
								onChange={(e: any) => onChangeRiskReducingMeasures(e, index)}
							/>
						</div>
						<div className={`${styles.form_item} ${styles.risk_title}`}>
							<div className={styles.risk}>
								<div
									{...(item.risk_after_measures.s.error
										? { className: styles.errorInput }
										: null)}
								>
									<input
										type="number"
										value={item.risk_after_measures.s.value}
										onChange={(e: any) =>
											onChangeRiskAfterMeasuresS(e.target.value, index)
										}
										className={styles.form_input}
									/>
								</div>
								<div
									{...(item.risk_after_measures.k.error
										? { className: styles.errorInput }
										: null)}
								>
									<input
										type="number"
										value={item.risk_after_measures.k.value}
										onChange={(e: any) =>
											onChangeRiskAfterMeasuresK(e.target.value, index)
										}
										className={styles.form_input}
									/>
								</div>
								<div
									{...(item.risk_after_measures.r.value === 0
										? null
										: item.risk_after_measures.r.value < 6
										? { className: styles.risk_green }
										: item.risk_after_measures.r.value < 15
										? { className: styles.risk_yellow }
										: { className: styles.risk_red })}
								>
									{item.risk_after_measures.r.value}
								</div>
							</div>
						</div>
						<div className={styles.form_item}>
							<TextArea
								value={item.comments.value}
								onChange={(e: any) => onChangeComments(e, index)}
							/>
						</div>
					</>
				);
			})}
			<div className={`${styles.form_item} ${styles.form_instrument}`}>
				<span onClick={addRow}>+</span>
				<span onClick={removeRow}>-</span>
			</div>
		</>
	);
};

export default observer(SJAMidle);
