export class JournalDTO {
  breathing_air: Object;
  emergency_air: Object;
  bailout: Object;
  extra_time: number;
  surface_left_time: string;
  at_the_bottom: Object;
  surface_return_time: string;
  total_dive_time: number;
  equipment: string;
  breathing_mix: string;
  safety_stop: Object;
  state_after_dive: Object;
  comments: string;
  saturation: string;
  usage_table: Object;
  constructor(model: any) {
    this.breathing_air = {
      type: model.breathing_air.type,
      str: model.breathing_air.str,
      trykk: model.breathing_air.trykk,
    };
    this.emergency_air = {
      type: model.emergency_air.type,
      str: model.emergency_air.str,
      trykk: model.emergency_air.trykk,
    };
    this.bailout = {
      str: model.bailout_air.str,
      trykk: model.bailout_air.trykk,
    };
    this.extra_time = model.extra_time.value;
    this.surface_left_time = datetimeToString(model.surface_left_time.value);
    this.at_the_bottom = {
      arrival_time: datetimeToString(model.at_the_bottom_time.arrival_time),
      leave_time: datetimeToString(model.at_the_bottom_time.leave_time),
      max_depth: model.at_the_bottom_time.max_depth,
      max_time: model.at_the_bottom_time.max_time,
    };
    this.surface_return_time = datetimeToString(
      model.surface_return_time.value
    );
    this.total_dive_time = model.total_dive_time.value;
    this.equipment = model.equipment.id;
    this.breathing_mix = model.breathing_mix.id;
    this.safety_stop = {
      type: model.safety_stop.type,
      safety_stop_3m: {
        arrival_time: datetimeToString(
          model.safety_stop.safety_stop_3m.arrival_time
        ),
        leave_time: datetimeToString(
          model.safety_stop.safety_stop_3m.leave_time
        ),
      },
      safety_stop_5m: {
        arrival_time: datetimeToString(
          model.safety_stop.safety_stop_5m.arrival_time
        ),
        leave_time: datetimeToString(
          model.safety_stop.safety_stop_5m.leave_time
        ),
      },
      safety_stop_6m: {
        arrival_time: datetimeToString(
          model.safety_stop.safety_stop_6m.arrival_time
        ),
        leave_time: datetimeToString(
          model.safety_stop.safety_stop_6m.leave_time
        ),
      },
      safety_stop_9m: {
        arrival_time: datetimeToString(
          model.safety_stop.safety_stop_9m.arrival_time
        ),
        leave_time: datetimeToString(
          model.safety_stop.safety_stop_9m.leave_time
        ),
      },
    };
    this.state_after_dive = {
      state: model.state_after_dive.state,
      description: model.state_after_dive.description,
    };
    this.comments = model.comments.value;
    this.saturation = model.saturation_dive.value;
    this.usage_table = {
      metter: model.usage_table.meter,
      minutes: model.usage_table.minutes,
    };
  }
}

function dateToString(date: string) {
  let year = String(new Date(date).getFullYear());
  let month = String(new Date(date).getMonth() + 1).padStart(2, '0');
  let day = String(new Date(date).getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
function datetimeToString(date: string) {
  if (date) {
    let hours = String(new Date(date).getHours()).padStart(2, '0');
    let minutes = String(new Date(date).getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  } else {
    return '';
  }
}
