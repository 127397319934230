import React, { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './Input.module.css';
import Modal from '../Modal/Modal';
import User from '../Forms/User/User';
import UserService from '../../services/UserSevice';
import { IUser } from '../../models/IUser';
interface SelectUserInstructionProps {
	onChange: (selectedUserIds: string[]) => void;
	value: IUser[];
}

const SelectUserInstruction: FC<SelectUserInstructionProps> = ({
	onChange,
	value,
}) => {
	const [users, setUsers] = useState<IUser[]>([]);
	const [selectedUsers, setSelectedUsers] = useState<IUser[]>(value);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [isDropdownVisible, setIsDropdownVisible] =
		useState<boolean>(false);

	async function getUsers() {
		const response = await UserService.fetchUsers();
		setUsers(response.data);
	}

	useEffect(() => {
		getUsers();
	}, []);
	useEffect(() => {
		setSelectedUsers(value);
	}, [value]);
	function handleUserClick(user: IUser) {
		const newSelectedUsers = [...selectedUsers, user];
		setSelectedUsers(newSelectedUsers);
		setUsers(users.filter((u) => u.id !== user.id));
		setIsDropdownVisible(false);
		onChange(newSelectedUsers.map((u) => u.id));
	}

	function handleRemoveUser(user: IUser) {
		const newSelectedUsers = selectedUsers.filter(
			(u) => u.id !== user.id
		);
		setSelectedUsers(newSelectedUsers);
		setUsers([...users, user]);
		onChange(newSelectedUsers.map((u) => u.id));
	}

	function handleSearchChange(
		event: React.ChangeEvent<HTMLInputElement>
	) {
		setSearchTerm(event.target.value);
		setIsDropdownVisible(true);
	}

	function toggleDropdown() {
		setIsDropdownVisible(!isDropdownVisible);
	}

	const filteredUsers = users.filter((user) =>
		`${user.name} ${user.surname}`
			.toLowerCase()
			.includes(searchTerm.toLowerCase())
	);

	return (
		<div className={styles.selectInst}>
			<div className={styles.selectSearch} onClick={toggleDropdown}>
				<h2>Distribusjonsliste</h2>
				<h3>Click to select</h3>
			</div>
			{isDropdownVisible && (
				<ul className={styles.dropdown}>
					<li>
						<input
							type="text"
							placeholder="Search users..."
							value={searchTerm}
							onChange={handleSearchChange}
						/>
					</li>{' '}
					{filteredUsers.map((user) => (
						<li key={user.id} onClick={() => handleUserClick(user)}>
							{user.name} {user.surname}
						</li>
					))}
				</ul>
			)}
			<div>
				<h3>Selected users:</h3>
				<ul>
					{selectedUsers.map((user) => (
						<li
							key={user.id}
							onClick={() => handleRemoveUser(user)}
							className={styles.selectedLi}>
							{user.name} {user.surname}
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default observer(SelectUserInstruction);
