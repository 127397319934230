import {
	FC,
	useContext,
	useState,
	useEffect,
	useLayoutEffect,
} from 'react';
import { Context } from '../../../index';
import { observer } from 'mobx-react-lite';
import Input from '../../Input/Input';
import Button from '../../Button/Button';
import styles from './UpdateReverence.module.css';

interface IProps {
	id: string;
	service: (id: string) => Promise<any>;
	callback?: () => void;
}

const DeleteReference: FC<IProps> = ({ id, service, callback }) => {
	const { store } = useContext(Context);

	return (
		<div className={styles.form}>
			<div>
				<Button
					text="Kanseller"
					onclick={() => {
						callback && callback();
					}}
				/>
			</div>
			<div>
				<Button
					text="Slett"
					onclick={() => {
						service(id);
						callback && callback();
					}}
					classname="red"
				/>
			</div>
		</div>
	);
};

export default observer(DeleteReference);
