import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileService from '../../services/FileService';
import {
	faFileImage,
	faFileAudio,
	faFileVideo,
	faFileAlt,
	faFile,
	faFilePdf,
	faFileArchive,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import styles from './Files.module.css';
const getFileIcon = (fileName: string) => {
	const fileExtension = fileName.split('.').pop()?.toLowerCase();
	const size = '3x';
	const iconStylePdf = { color: 'red', margin: '0 20px' };
	const iconStyle = { color: 'black', margin: '0 20px' };
	const iconStyleImage = { color: 'green', margin: '0 20px' };
	const iconStyleAudio = { color: 'blue', margin: '0 20px' };
	const iconStyleVideo = { color: 'orange', margin: '0 20px' };
	const iconStyleAlt = { color: 'purple', margin: '0 20px' };
	const iconStyleArchive = { color: 'brown', margin: '0 20px' };
	switch (fileExtension) {
		case 'jpg':
		case 'jpeg':
		case 'png':
		case 'gif':
		case 'bmp':
		case 'tiff':
		case 'webp':
			return (
				<FontAwesomeIcon
					icon={faFileImage}
					size={size}
					style={iconStyleImage}
				/>
			);
		case 'mp3':
		case 'wav':
		case 'flac':
		case 'm4a':
		case 'aac':
			return (
				<FontAwesomeIcon
					icon={faFileAudio}
					size={size}
					style={iconStyleAudio}
				/>
			);
		case 'mp4':
		case 'mkv':
		case 'avi':
		case 'mov':
		case 'flv':
		case 'wmv':
			return (
				<FontAwesomeIcon
					icon={faFileVideo}
					size={size}
					style={iconStyleVideo}
				/>
			);
		case 'txt':
		case 'md':
		case 'log':
		case 'csv':
		case 'json':
		case 'xml':
			return (
				<FontAwesomeIcon
					icon={faFileAlt}
					size={size}
					style={iconStyleAlt}
				/>
			);
		case 'pdf':
			return (
				<FontAwesomeIcon
					icon={faFilePdf}
					size={size}
					style={iconStylePdf}
				/>
			);
		case 'zip':
		case 'rar':
		case 'tar':
		case 'gz':
		case '7z':
			return (
				<FontAwesomeIcon
					icon={faFileArchive}
					size={size}
					style={iconStyleArchive}
				/>
			);

		default:
			return <FontAwesomeIcon icon={faFile} size={size} />;
	}
};

type UploadProgress = {
	[fileName: string]: number;
};
type FileUploadProps = {
	files: File[];
	setFiles: React.Dispatch<React.SetStateAction<File[]>>;
	onChange: (files: File[]) => void;
};
const FileUpload: React.FC<FileUploadProps> = ({
	files,
	setFiles,
	onChange,
}) => {
	const [uploadProgress, setUploadProgress] =
		useState<UploadProgress>({});

	const onDrop = useCallback(async (acceptedFiles: File[]) => {
		setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);

		await handleUpload(acceptedFiles);
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
	});
	useEffect(() => {
		onChange(files);
	}, [files, onChange]);

	const handleUpload = async (filesToUpload: File[]) => {
		const progress: UploadProgress = {};

		for (const file of filesToUpload) {
			progress[file.name] = 0;
			setUploadProgress(progress);

			const result = await FileService.uploadFiles(
				[file],
				(progressEvent: ProgressEvent) => {
					const percentCompleted = Math.round(
						(progressEvent.loaded * 100) / progressEvent.total
					);
					progress[file.name] = percentCompleted;
					setUploadProgress({ ...progress });
				}
			);

			if (result.status === 200) {
				progress[file.name] = 100;
				setUploadProgress(progress);
			}
		}
	};

	const removeFile = (index: number) => {
		setFiles((prevFiles) => {
			const updatedFiles = [...prevFiles];
			updatedFiles.splice(index, 1);
			return updatedFiles;
		});
	};

	const formatFileSize = (size: number): string => {
		if (size < 1000) {
			return `${size} bytes`;
		} else if (size < 500 * 1024) {
			return `${(size / 1024).toFixed(2)} kb`;
		} else if (size < 500 * 1024 * 1024) {
			return `${(size / (1024 * 1024)).toFixed(2)} mb`;
		} else {
			return `${(size / (1024 * 1024 * 1024)).toFixed(2)} gb`;
		}
	};
	return (
		<div className={styles.main}>
			<div {...getRootProps()} className={styles.zone}>
				<input {...getInputProps()} />
				{isDragActive ? (
					<p>Drag the files here...</p>
				) : (
					<p>Click or drag the files to download</p>
				)}
			</div>
			<ul className={styles.listing}>
				{files.map((file, index) => (
					<li key={file.name}>
						{getFileIcon(file.name)} {file.name} -{' '}
						{formatFileSize(file.size)}
						<FontAwesomeIcon
							icon={faTrash}
							className="file-remove"
							onClick={() => removeFile(index)}
							size="2x"
							style={{ color: 'red', margin: '0 20px' }}
						/>
					</li>
				))}
			</ul>
		</div>
	);
};

export default FileUpload;
