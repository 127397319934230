import { FC, useState, useEffect, useContext } from 'react';
import { Context } from '../../index';
import ReferencesService from '../../services/ReferencesService';
import UserService from '../../services/UserSevice';

import { CSVLink, CSVDownload } from 'react-csv';
import styles from './Test.module.css';
import { NONAME } from 'dns';

interface IProps {
	data: any;
}
const CVS: FC<IProps> = ({ data }) => {
	const [journalData, setJournalData] = useState<any>([]);
	const headers = [
		{ label: 'Dykkejournal №', key: 'number' },
		{ label: 'Dato', key: 'date' },
		{ label: 'Oppdragsgiver', key: 'client' },
		{ label: 'Oppdragsgiver adresse', key: 'client_location' },
		{ label: 'Sted', key: 'location' },
		{ label: 'Dykkemetode', key: 'dive_method.name' },
		{ label: 'Arbeidsbeskrivelse', key: 'description' },
		{ label: 'Dykkeleder', key: 'lider.fullname' },
		{ label: 'Dykkeleder Epost', key: 'lider.email' },
		{ label: 'Dykker 1', key: 'diver1.fullname' },
		{ label: 'Dykker 1 Epost', key: 'diver1.email' },
		{ label: 'Dykker 2', key: 'diver2.fullname' },
		{ label: 'Dykker 2 Epost', key: 'diver2.email' },
		{ label: 'Assistent', key: 'assistent.fullname' },
		{ label: 'Assistent Epost', key: 'assistent.email' },
		{ label: 'Kunderepresentant', key: 'client_representative' },
		// DIVER 1
		{
			label: 'Dykker 1 Pusteluft STR/Liter',
			key: 'diver1_info.breathing_air.str',
		},
		{
			label: 'Dykker 1 Pusteluft Trykk/BAR',
			key: 'diver1_info.breathing_air.trykk',
		},
		{
			label: 'Dykker 1 Nødluft STR/Liter',
			key: 'diver1_info.emergency_air.str',
		},
		{
			label: 'Dykker 1 Nødluft Trykk/BAR',
			key: 'diver1_info.emergency_air.trykk',
		},
		{
			label: 'Dykker 1 Bailout STR/Liter',
			key: 'diver1_info.bailout.str',
		},
		{
			label: 'Dykker 1 Bailout Trykk/BAR',
			key: 'diver1_info.bailout.trykk',
		},
		{
			label: 'Dykker 1 Tillegg bunntid (min.)',
			key: 'diver1_info.extra_time',
		},
		{
			label: 'Dykker 1 Forlot overflaten',
			key: 'diver1_info.surface_left_time',
		},
		{
			label: 'Dykker 1 På bunnen Ankom',
			key: 'diver1_info.at_the_bottom.arrival_time',
		},
		{
			label: 'Dykker 1 På bunnen Forlot',
			key: 'diver1_info.at_the_bottom.leave_time',
		},
		{
			label: 'Dykker 1 På bunnen Maks. dybde',
			key: 'diver1_info.at_the_bottom.max_depth.$numberDecimal',
		},
		{
			label: 'Dykker 1 På bunnen Bunntid (min.)',
			key: 'diver1_info.at_the_bottom.max_time',
		},
		{
			label: 'Dykker 1 Dekostopp 9m Ankom',
			key: 'diver1_info.safety_stop.safety_stop_9m.arrival_time',
		},
		{
			label: 'Dykker 1 Dekostopp 9m Forlot',
			key: 'diver1_info.safety_stop.safety_stop_9m.leave_time',
		},
		{
			label: 'Dykker 1 Dekostopp 6m Ankom',
			key: 'diver1_info.safety_stop.safety_stop_6m.arrival_time',
		},
		{
			label: 'Dykker 1 Dekostopp 6m Forlot',
			key: 'diver1_info.safety_stop.safety_stop_6m.leave_time',
		},
		{
			label: 'Dykker 1 Dekostopp 3m Ankom',
			key: 'diver1_info.safety_stop.safety_stop_3m.arrival_time',
		},
		{
			label: 'Dykker 1 Dekostopp 3m Forlot',
			key: 'diver1_infosafety_stop.safety_stop_3m.leave_time',
		},
		{
			label: 'Dykker 1 Dekostopp 5m Ankom',
			key: 'diver1_info.safety_stop.safety_stop_5m.arrival_time',
		},
		{
			label: 'Dykker 1 Dekostopp 5m Forlot',
			key: 'diver1_info.safety_stop.safety_stop_5m.leave_time',
		},
		{
			label: 'Dykker 1 På overflaten',
			key: 'diver1_info.surface_return_time',
		},
		{
			label: 'Dykker 1 Total dykketid (min.)',
			key: 'diver1_info.total_dive_time',
		},
		{ label: 'Dykker 1 Utstyr', key: 'diver1_info.equipment.name' },
		{
			label: 'Dykker 1 Pustegass',
			key: 'diver1_info.breathing_mix.name',
		},
		{
			label: 'Dykker 1 Deko/sikkerhets-stopp',
			key: 'diver1_info.safety_stop.type',
		},
		{
			label: 'Dykker 1 Tilstand etter dykk',
			key: 'diver1_info.state_after_dive.state',
		},
		{
			label: 'Dykker 1 Beskrivelse av tilstand',
			key: 'diver1_info.state_after_dive.description',
		},
		{ label: 'Dykker 1 Kommentarer', key: 'diver1_info.comments' },
		{
			label: 'Dykker 1 Metningsgruppe',
			key: 'diver1_info.saturation',
		},
		{
			label: 'Dykker 1 Tabell benyttet Meter',
			key: 'diver1_info.usage_table.metter',
		},
		{
			label: 'Dykker 1 Tabell benyttet Minutter',
			key: 'diver1_info.usage_table.minutes',
		},

		// DIVER 2
		{
			label: 'Dykker 2 Pusteluft STR/Liter',
			key: 'diver2_info.breathing_air.str',
		},
		{
			label: 'Dykker 2 Pusteluft Trykk/BAR',
			key: 'diver2_info.breathing_air.trykk',
		},
		{
			label: 'Dykker 2 Nødluft STR/Liter',
			key: 'diver2_info.emergency_air.str',
		},
		{
			label: 'Dykker 2 Nødluft Trykk/BAR',
			key: 'diver2_info.emergency_air.trykk',
		},
		{
			label: 'Dykker 2 Bailout STR/Liter',
			key: 'diver2_info.bailout.str',
		},
		{
			label: 'Dykker 2 Bailout Trykk/BAR',
			key: 'diver2_info.bailout.trykk',
		},
		{
			label: 'Dykker 2 Tillegg bunntid (min.)',
			key: 'diver2_info.extra_time',
		},
		{
			label: 'Dykker 2 Forlot overflaten',
			key: 'diver2_info.surface_left_time',
		},
		{
			label: 'Dykker 2 På bunnen Ankom',
			key: 'diver2_info.at_the_bottom.arrival_time',
		},
		{
			label: 'Dykker 2 På bunnen Forlot',
			key: 'diver2_info.at_the_bottom.leave_time',
		},
		{
			label: 'Dykker 2 På bunnen Maks. dybde',
			key: 'diver2_info.at_the_bottom.max_depth.$numberDecimal',
		},
		{
			label: 'Dykker 2 På bunnen Bunntid (min.)',
			key: 'diver2_info.at_the_bottom.max_time',
		},
		{
			label: 'Dykker 2 Dekostopp 9m Ankom',
			key: 'diver2_info.safety_stop.safety_stop_9m.arrival_time',
		},
		{
			label: 'Dykker 2 Dekostopp 9m Forlot',
			key: 'diver2_info.safety_stop.safety_stop_9m.leave_time',
		},
		{
			label: 'Dykker 2 Dekostopp 6m Ankom',
			key: 'diver2_info.safety_stop.safety_stop_6m.arrival_time',
		},
		{
			label: 'Dykker 2 Dekostopp 6m Forlot',
			key: 'diver2_info.safety_stop.safety_stop_6m.leave_time',
		},
		{
			label: 'Dykker 2 Dekostopp 3m Ankom',
			key: 'diver2_info.safety_stop.safety_stop_3m.arrival_time',
		},
		{
			label: 'Dykker 2 Dekostopp 3m Forlot',
			key: 'diver2_infosafety_stop.safety_stop_3m.leave_time',
		},
		{
			label: 'Dykker 2 Dekostopp 5m Ankom',
			key: 'diver2_info.safety_stop.safety_stop_5m.arrival_time',
		},
		{
			label: 'Dykker 2 Dekostopp 5m Forlot',
			key: 'diver2_info.safety_stop.safety_stop_5m.leave_time',
		},
		{
			label: 'Dykker 2 På overflaten',
			key: 'diver2_info.surface_return_time',
		},
		{
			label: 'Dykker 2 Total dykketid (min.)',
			key: 'diver2_info.total_dive_time',
		},
		{ label: 'Dykker 2 Utstyr', key: 'diver2_info.equipment.name' },
		{ label: 'Dykker 2 Pustegass', key: 'diver2_info.breathing_mix.name' },
		{
			label: 'Dykker 2 Deko/sikkerhets-stopp',
			key: 'diver2_info.safety_stop.type',
		},
		{
			label: 'Dykker 2 Tilstand etter dykk',
			key: 'diver2_info.state_after_dive.state',
		},
		{
			label: 'Dykker 2 Beskrivelse av tilstand',
			key: 'diver2_info.state_after_dive.description',
		},
		{ label: 'Dykker 2 Kommentarer', key: 'diver2_info.comments' },
		{
			label: 'Dykker 2 Metningsgruppe',
			key: 'diver2_info.saturation',
		},
		{
			label: 'Dykker 2 Tabell benyttet Meter',
			key: 'diver2_info.usage_table.metter',
		},
		{
			label: 'Dykker 2 Tabell benyttet Minutter',
			key: 'diver2_info.usage_table.minutes',
		},
	];
	const [progress, setProgress] = useState<Number>(0);
	const [maxProgress, setMaxProgress] = useState<Number>(0);

	useEffect(() => {
		formatData(data);
	}, [data]);
	function formatData(data: any) {
		for (let i = 0; i < data.length; i++) {
			data[i].date = dateToString(data[i].date);
		}
		setJournalData(data);
	}
	function dateToString(date: string) {
		let newDate = new Date(date);
		let year = String(newDate.getFullYear());
		let month = String(newDate.getMonth() + 1).padStart(2, '0');
		let day = String(newDate.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
	}
	// async function getOneDiveMethod(id: string) {
	// 	console.log(id);
	// 	const response = await ReferencesService.getOneDiveMethod(id);
	// 	let name = response.data.name;
	// 	return name;
	// }
	// async function getOneEquipment(id: string) {
	// 	const response = await ReferencesService.getOneEquipment(id);
	// 	let name = response.data.name;
	// 	return name;
	// }
	// async function getOneBreathingMix(id: string) {
	// 	const response = await ReferencesService.getOneBreathingMix(id);
	// 	let name = response.data.name;
	// 	return name;
	// }
	// async function getOneUser(id: string) {
	// 	const response = await UserService.getOne(id);
	// 	let fullName = response.data.name + ' ' + response.data.surname;
	// 	return fullName;
	// }
	// async function formatData(newAraay: any) {
	// 	// Create an array of Promises that will be resolved concurrently
	// 	const promises = newAraay.map(async (entry: any) => {
	// 		// Format the date and dive method synchronously
	// 		entry.date = dateToString(entry.date);

	// 		// Retrieve user info, equipment, and breathing mix asynchronously
	// 		const [
	// 			lider,
	// 			diver1,
	// 			diver2,
	// 			assistent,
	// 			diver1Info,
	// 			diver2Info,
	// 			dive_method,
	// 		] = await Promise.all([
	// 			getOneUser(entry.lider.id),
	// 			getOneUser(entry.diver1.id),
	// 			getOneUser(entry.diver2.id),
	// 			getOneUser(entry.assistent.id),
	// 			getOneEquipment(entry.diver1_info.equipment),
	// 			getOneEquipment(entry.diver2_info.equipment),
	// 			getOneBreathingMix(entry.diver1_info.breathing_mix),
	// 			getOneBreathingMix(entry.diver2_info.breathing_mix),
	// 			getOneDiveMethod(entry.dive_method),
	// 		]);

	// 		// Update the entry with the retrieved info
	// 		entry.lider.fullname = lider;
	// 		entry.diver1.fullname = diver1;
	// 		entry.diver2.fullname = diver2;
	// 		entry.assistent.fullname = assistent;
	// 		entry.diver1_info.equipment = diver1Info.equipment;
	// 		entry.diver2_info.equipment = diver2Info.equipment;
	// 		entry.diver1_info.breathing_mix = diver1Info.breathing_mix;
	// 		entry.diver2_info.breathing_mix = diver2Info.breathing_mix;
	// 		entry.dive_method = dive_method;

	// 		// Update the diver states asynchronously
	// 		if (entry.diver1_info.state_after_dive.state == 1) {
	// 			entry.diver1_info.state_after_dive.state = 'Dykker Ok';
	// 		} else if (entry.diver1_info.state_after_dive.state == 2) {
	// 			entry.diver1_info.state_after_dive.state = 'Dykker ikke Ok';
	// 		}
	// 		if (entry.diver2_info.state_after_dive.state == 1) {
	// 			entry.diver2_info.state_after_dive.state = 'Dykker Ok';
	// 		} else if (entry.diver2_info.state_after_dive.state == 2) {
	// 			entry.diver2_info.state_after_dive.state = 'Dykker ikke Ok';
	// 		}
	// 	});

	// 	// Wait for all Promises to resolve before updating state
	// 	await Promise.all(promises);
	// 	setJournalData(newAraay);
	// }

	return (
		<div>
			{journalData.length ? (
				<CSVLink
					data={journalData}
					headers={headers}
					className={styles.button}>
					Download CSV
				</CSVLink>
			) : (
				<div>Wait, CSV generation is in progress... </div>
			)}
		</div>
	);
};

export default CVS;
