import $api from "../http";
import { AxiosResponse } from "axios";
import { IUser } from "../models/IUser";


export default class UserService {
    static fetchUsers(): Promise<AxiosResponse<IUser[]>> {
        return $api.get<IUser[]>('/users/all');
    }
    static updateUser(id :string, name: string, surname: string, email: string, userType:number ): Promise<AxiosResponse<IUser>> {
        return $api.post<IUser>(`/users/update/full/${id}`, {name, surname, email, userType});
    }
    static updateOneField(id :string, field: string, value: string): Promise<AxiosResponse<IUser>> {
        return $api.post<IUser>(`/users/update/${id}`, {field, value});
    }
    static deleteUser(id :string): Promise<AxiosResponse<IUser>> {
        return $api.delete<IUser>(`/users/${id}`);
    }
    static async getLiders(): Promise<AxiosResponse<IUser[]>> {
        return $api.get<IUser[]>('/users/type/2');
    }
    static async getDivers(): Promise<AxiosResponse<IUser[]>> {
        return $api.get<IUser[]>('/users/type/1');
    }
    static async getAssistents(): Promise<AxiosResponse<IUser[]>> {
        return $api.get<IUser[]>('/users/type/3');
    }
    static async getOne(id: string): Promise<AxiosResponse<IUser>> {
        return $api.get<IUser>(`/users/${id}`);
    }
}