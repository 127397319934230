import { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Input from '../../Input/Input';
import Button from '../../Button/Button';
import styles from './AddReference.module.css';

interface IProps {
	service?: (value: any) => void;
	title: string;
	callback: () => void;
}

const AddReference: FC<IProps> = ({ service, title, callback }) => {
	const [name, setName] = useState<any>({ value: '', error: false });
	function addReference() {
		service && service(name.value);
		callback();
	}
	return (
		<div className={styles.addReference}>
			<div {...(name.error ? { className: styles.error } : {})}>
				<Input
					type="text"
					placeholder="Name"
					value={name.value}
					onchange={(e) =>
						setName({ ...name, value: e.target.value })
					}
				/>
			</div>
			<Button
				text={`Leg til ny new ${title}`}
				onclick={addReference}
			/>
		</div>
	);
};

export default observer(AddReference);
